import { Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import style from './../../assets/css/cookie.module.scss';

const CookiePopup = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);

  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/' });
  };

  if (cookies.cookieConsent) {
    return null; // Popup already accepted
  }

  return (
    <div className={style.cookie_popup}>
      <p>Esse website utiliza cookies. Ao utilizar este website, você concorda com o uso de cookies.</p>
      <Button 
        size='medium'
        variant='outlined'
        onClick={handleAccept}
        sx={{
          textTransform: 'none',
          fontSize: '1.5rem'
        }}
      >
        Concordo
      </Button>
    </div>
  );
};

export default CookiePopup;
