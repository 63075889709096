import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import style from '../../assets/css/quicklinks.module.scss';
import { Grid, Button, Container } from '@mui/material';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function TopNav() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div id='topnav' className={style.topNav}>
			<Container>
				<Grid
					item
					xs={12}
					sm
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Grid item>
						<div className={style.ntSocialMedia}>
							<a href='https://www.facebook.com/profile.php?id=100057271031834' target='_blank' rel="noreferrer">
								<FacebookIcon />
							</a>
							<a href='https://www.instagram.com/consulado_geral_gb_albufeira?igsh=MmY0MWVic3NhM2Zp' target='_blank' rel="noreferrer">
								<InstagramIcon />
							</a>
							<a href='https://twitter.com/cgrgba' target='_blank' rel="noreferrer">
								<XIcon />
							</a>
						</div>
					</Grid>
					<Grid item alignContent={'flex-end'}>
						<div className={style.ntEmergency}>
							<a href='mailto:geral@cgrgba.gw'>
								<EmailIcon />
								geral@cgrgba.gw
							</a>
							<Button
								variant='outlined'
								endIcon={<ChevronRightIcon />}
								className={style.emergencyBtn}
								sx={{ textTransform: 'none' }}
							>
								<Link 
									to='/servicos/efetuar-agendamento'
									sx={{ textTransform: 'none !important' }}
								>
									Efetuar agendamento
								</Link>
							</Button>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
