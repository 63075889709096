import { Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

export default function MensagemConsul() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Mensagem do Cônsul-Geral</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<p>Caro(a) Utilizador(a),</p>
					<p className='text-justify'>
						É com enorme prazer que lhe dou as boas-vindas ao sítio eletrónico do{' '}
						<span style={{ fontWeight: 500 }}>
							Consulado Geral da República da Guiné-Bissau no Algarve
						</span>
						.
					</p>
					<p className='text-justify'>
						Como porta de entrada digital para a{' '}
						<span style={{ fontWeight: 500 }}>República da Guiné-Bissau</span>, o
						presente sítio eletrónico destina-se a proporcionar-lhe o acesso, com maior
						comodidade, à informação sobre este país acolhedor, com uma rica diversidade
						cultural e etnográfica, bem como potencialidades, oportunidades de negócios
						e vantagens competitivas singulares.
					</p>
					<p>
						Adicionalmente e em particular, o presente sítio eletrónico visa
						proporcionar-lhe, caro(a) Utilizador(a), o acesso aos serviços consulares
						disponíveis online, bem como os dados de contacto e de agendamento dos
						serviços consulares apenas prestados ao balcão.
					</p>

					<p className='text-justify'>
						Espero que o acesso e a utilização do presente sítio eletrónico obedeçam aos
						respetivos{' '}
						<a href='/termos' target='_blank'>
							Termos e Condições
						</a>{' '}
						e{' '}
						<a href='/privacidade' target='_blank'>
							Política de Privacidade
						</a>{' '}
						e que, no mesmo, se encontrem respostas às questões do(a) Utilizador(a).
						Caso tal não aconteça, estaremos naturalmente disponíveis para responder às
						questões do(a) Utilizador(a) e/ou encaminhá-las para as entidades
						competentes.
					</p>
					<p className='text-justify'>
						Por fim, resta-me desejar-lhe, caro(a) Utilizador(a), uma boa leitura e
						agradecer-lhe por visitar o sítio eletrónico do Consulado Geral da República
						da Guiné-Bissau no Algarve.
					</p>

					<p style={{ marginBlock: 0 }}>Cônsul-Geral</p>
					<p style={{ fontWeight: 600, marginBlock: 0 }}>Embaixadora Josefina Kosta</p>
				</Paper>
			</Container>
		</div>
	);
}
