import { FileDownload } from '@mui/icons-material'
import { Button } from '@mui/material'

export default function DownloadButton({link}) {
  return (
    <Button
        variant="outlined"
        sx={{color:'hsla(0.0, 0%, 0%, 0.87)', textTransform: 'none', borderColor: 'hsla(0.0, 0%, 0%, 0.87)', fontSize: '1.8rem'}}
        endIcon={<FileDownload />}
        href={link}
        download
        >
            Fazer download
    </Button>
  )
}
