import { Container, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import emblema from '../../assets/images/logo-login.png';
import footerStyle from './footer.module.scss';
import classNames from 'classnames/bind';

export default function FooterComponent() {
	const sc = classNames.bind(footerStyle);
	return (
		<footer>
			<section className={footerStyle.top}>
				<Container>
					<Grid container justifyContent='center' sx={{ marginBlock: '40px', paddingTop: '20px', paddingBottom:'20px' }}>
						<div style={{padding: '20px', borderRadius: '50%', backgroundColor: '#FFF'}}>
							<img
								src={emblema}
								style={{ width: '200px',
										margin: '10px'
									}}
								alt='Brasão de armas'
							/>
						</div>
					</Grid>
					<div className={footerStyle.links} style={{ justifyContent: 'center' }}>
						<div className={footerStyle.linksColumn}>
							<h3>Contactos</h3>
							<a href='tel:+351961941934'>
								<PhoneIphoneIcon />
								00351 961 941 934
							</a>
							<a href='tel:+351289588084'>
								<PhoneIcon />
								00351 289 588 084
							</a>
							<a
								href='https://www.google.pt/maps/place/@37.0917861,-8.2554155?q=Consulado+Geral+da+Guiné+Bissau'
								className={footerStyle.oddOne}
							>
								<LocationOnIcon />
								Avenida da Liberdade Nº 131 <br />
								Escritório 8, 8200-002 Albufeira
							</a>
						</div>
						<div className={footerStyle.linksColumn}>
							<h3>Utilizadores</h3>
							<a href='/termos'>Termos de Utilização</a>
							<a href='/privacidade'>Privacidade</a>
						</div>
						<div className={sc('socialsColumn')}>
							<h3>Redes sociais</h3>
							<p>Siga-nos nas redes sociais...</p>
							<div className={footerStyle.socials}>
								<a href='https://www.facebook.com/profile.php?id=100057271031834' target='_blank'>
									<FacebookIcon className={footerStyle.footerSocial} />
								</a>
								<a href='https://www.instagram.com/consulado_geral_gb_albufeira?igsh=MmY0MWVic3NhM2Zp' target='_blank'>
									<InstagramIcon className={footerStyle.footerSocial} />
								</a>
								<a href='https://twitter.com/cgrgba'>
									<XIcon className={footerStyle.footerSocial} />
								</a>
							</div>
						</div>
					</div>
				</Container>
			</section>
			<section className={footerStyle.bottomWrapper}>
				<Container>
					<div className={footerStyle.bottom}>
						<p className={footerStyle.copyright}>
							{' © '} {new Date().getFullYear()} Consulado Geral da República da
							Guiné-Bissau no Algarve. Todos os Direitos Reservados.
						</p>
						<p className={footerStyle.legal}>
							Powered by{' '}
							<a
								href='https://nantoidigital.com'
								target='_blank'
								rel='noreferrer'
								style={{
									color: '#FEFEFE',

									fontWeight: 600,
									fontSize: '1.4rem',
								}}
							>
								Nantoi Digital
							</a>
						</p>
					</div>
				</Container>
			</section>
		</footer>
	);
}
