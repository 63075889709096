import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import '../../assets/css/global.scss';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';
import { getCurrentDay, getFormattedFullDate, getFormattedFullDay, getLastDayOfMonth } from '../../utils/DateUtils';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Container, Divider, Grid, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HourAndDataInformation({ setdate, sethour, category,
	service, setdateparam}) {
	const [timeSlotList, setTimeSlotList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [initialState, setInitialState] = useState(false);
	const [selectedDate,setSelectedDate] = useState('');

	const URL_TIME_SLOTS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_TIME_SLOTS}`;

	useEffect(() => {
		setInitialState(true);
	}, []);

	const handleSchedulingDate = (newDate) => {
		setInitialState(false);
		sethour('');
		const selected = newDate?.format('YYYY-MM-DD');
		setSelectedDate(newDate);
		if (selected) {
			getSlotTimeByDate(selected);
		}
	};

	const handleClick = (id, slot, date, event) => {
		event.preventDefault();
		const slotElementList = document.getElementsByClassName('slot_time');

		for (let i in slotElementList) {
			const e = slotElementList[i];
			if (e.id == id && e?.classList?.contains('selected')) {
				e.classList.remove('selected');
				sethour('');
				setdate('');
			} else {
				if (e.id == id) {
					e?.classList?.add('selected');
					sethour(slot);
					setdate(getFormattedFullDate(date));
					setdateparam(date);
				} else {
					e?.classList?.remove('selected');
				}
			}
		}
	};

	const reqOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo() },
	};

	const getSlotTimeByDate = React.useCallback((date) => {
		let tempTimeSlotList = [];
        setLoading(true);
        setTimeSlotList([]);
		fetch(`${URL_TIME_SLOTS}?pageSize=9999&beginTimeSlotDate=${getCurrentDay(date)}&endTimeSlotDate=${getLastDayOfMonth(date)}&categoryCode=${category}&serviceCode=${service}&orderColumn=beginDate&direction=ASC&v=${new Date().valueOf()}`,
		reqOptions)
			.then(async (response) => {
				const isJson = response.headers.get('content-type')?.includes('application/json');
				const resp = isJson && (await response.json());

				if (!response.ok) {
					return handleError(response, resp);
				}

				const slots = resp?.content;
				slots?.forEach(slot => {
                    tempTimeSlotList.push(slot);
                });

                setTimeSlotList(tempTimeSlotList);  
                setLoading(false);
                setInitialState(false);   
			})
			.catch((error) => {
				setLoading(false);
				console.error('There was an error!', error);
			});
	});

	const handleError = (response, data) => {
		const error = (data && data.message) || response.status;
		return Promise.reject(error);
	};

	return (
		<React.Fragment>
			<Container sx={{marginTop: '20px'}}>
				<h2>Por favor, selecione mês e o ano pretendido.</h2>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
				<DatePicker
					value={selectedDate}
					minDate={dayjs(new Date())}
					defaultValue={dayjs(new Date())}
					disablePast
					views={['month', 'year']}
					onAccept={(newDate) => handleSchedulingDate(newDate)}
					label='Mês e ano'
					slotProps={{
						textField: {
							fullWidth: true,
							variant: 'standard',
							required: true,
						},
					}}
					sx={{
						'& .PrivatePickersYear-yearButton': { fontSize: '1.8rem' },
						marginTop: '20px'
					}}
				/>
				</LocalizationProvider>

				<Divider sx={{marginTop: '20px'}} />

				<Grid padding={3}>
					{loading ? (
						<Grid>
							<p style={{ marginBottom: 5 }}>Por favor aguarde...</p>
						</Grid>
					) : (
					<>
					
						<Grid style={{paddingTop: '20px'}}>
							{!initialState && timeSlotList?.length > 0 && (
							<p style={{ marginBottom: '20px' }}>
								Dias com os horários disponíveis para o agendamento:
							</p>
						)}
						<div>
							<List>
								{
									timeSlotList?.map((timeSlot, index) => (
										<div style={{marginBottom: '10px'}} key={index}>
											<Accordion defaultExpanded={index===0} sx={{marginBottom: '5px'}} key={index}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
												>
													<b>{getFormattedFullDay(timeSlot.beginDate)}</b>
												</AccordionSummary>
												<AccordionDetails>
													{ timeSlot?.hours?.map( (slot) => (
														<Chip
															key={slot.id}
															label={slot.value}
															id={slot.id}
															className='slot_time'
															variant='outlined'
															onClick={(e) => handleClick(slot.id, slot.value, timeSlot.beginDate, e)}
														/>   
														))
													}
												</AccordionDetails>
											</Accordion> 
										</div>
									))
								}
							</List>
							</div>
						</Grid>
						{(timeSlotList === null || timeSlotList?.length === 0) &&
							!initialState && (
								<p style={{ marginBottom: 5 }}>
									Sem horários disponíveis para a data selecionada.
								</p>
							)}
						</>
					)}
				</Grid>
			</Container>
		</React.Fragment>
	);
}
