import CryptoJS from 'crypto-js';

const secret = process.env.REACT_APP_API_SECRET_CLIENT_KEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
};

export const decryptData = (encryptedData) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, secret).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export const getEncryptedClientHeaderInfo = () => {
  return encryptData(process.env.REACT_APP_CLIENT_VALUE);
};
