import { Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Migracao() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Migração</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h2>Passaporte</h2>
					<p>
						O Passaporte é um documento de viagem individual, que permite ao seu titular
						a entrada e saída do território nacional, bem como do território de outros
						Estados que o reconheçam para esse efeito.
						<br /> Só o próprio, presencialmente, pode requerer o passaporte.
						<br /> No caso de menores de 18 anos, cidadãos interditos ou inabilitados, o
						passaporte é requerido pelo próprio, por quem exerce o poder parental, a
						tutela ou a curatela.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Formulário devidamente preenchido.</li>
						<li>Original e fotocópia do Passaporte de que é titular.</li>
						<li>Cartão de inscrição consular válido.</li>
						<li>Uma fotografia tipo passe, actual e fundo branco.</li>
						<li>Comprovativo/recibo do depósito dos valores correspondes.</li>
						<li>
							Documento de identificação do representante legal (quando se tratar de
							menor).
						</li>
					</ul>
					<p>
						Em caso de perda, distribuição, furto ou extravio, o titular do passaporte
						deve comunicar imediatamente tal facto à entidade emissora, juntando a
						declaração de perda passada pelas entidades policiais do país da ocorrência.
					</p>
					<h2 style={{marginTop: '60px'}}>Salvo Conduto</h2>
					<p>
						O salvo-conduto é emitido pelas Missões Diplomáticas e Consulares guineense,
						válido para uma viagem única de regresso ao país de origem. Utilizável no
						prazo de 30 dias a contar da data da sua emissão.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Formulário devidamente preenchido.</li>
						<li>Original e Fotocópia da Autorização de Residência em Portugal.</li>
						<li>Original e Fotocópia do Bilhete de Passagem;</li>
						<li>Original e Fotocópia do cartão de inscrição consular válido;</li>
						<li>Original e Fotocópia do BI OU Certidão de Nascimento.</li>
						<li>Cédula Pessoal, apenas para menores de idade.</li>
						<li>
							Original e Fotocópia do(s) Passaporte(s) que possua, mesmo que
							caducados.
						</li>
						<li>
							(2) Duas fotografias iguais, tipo passe, coloridas, actuais, originais,
							em fundo branco, sem óculos escuros (Salvo por recomendação médica
							comprovada);
						</li>
						<li>Cartão de inscrição consular válido.</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>

					<h2 style={{marginTop: '60px'}}>Declaração para SEF</h2>
					<p>
						Documento emitido para fins a que se destina, quando solicitado pelas
						autoridades portuguesas.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Passaporte</li>
						<li>Cartão de inscrição consular</li>
						<li>Documento que prova o pedido.</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>

					<h2 style={{marginTop: '60px'}}>Vistos</h2>
					<p>
						O Visto é uma autorização do Estado que permite ao cidadão estrangeiro
						transitar, entrar e permanecer no território nacional, mediante observância
						dos limites e condicionalismos previstos na Lei.
						<br /> Pode solicitar vistos em qualquer Missões Diplomáticas e Consulares
						da República de Guiné-Bissau.
						<br />
						No caso de não existir uma Missão Diplomática ou Posto Consular, os
						requerentes poderão solicitar o visto no país mais próximo da sua área de
						residência.
						<br /> Os menores que viajam, quando não forem acompanhados por quem exerça
						o poder paternal, só podem entrar e sair de território nacional exibindo
						autorização para o efeito.
						<br /> A autorização referida deve constar de documento escrito, datado e
						com a assinatura de quem exerce o poder paternal, legalmente certificada,
						conferindo poderes de acompanhamento por parte de terceiros, devidamente
						identificados.
					</p>
					<h3>Categorias de vistos</h3>
					<p>Este Consulado emite 4 categorias de vistos:</p>
					<ul>
						<li>Diplomático</li>
						<li>Cortesia</li>
						<li>Turismo</li>
						<li>Trânsito</li>
					</ul>
					<h3>Requisitos</h3>
					<ul>
						<li>Ser portador de passaporte com validade mínima de 3 meses.</li>
						<li>
							A validade descrita no ponto 1 altera se o período de estada for
							superior a esta data.
						</li>
						<li>
							Formulário devidamente preenchido acompanhado da nota verbal do MNE,
							quando tratar do passaporte diplomático ou serviço, uma fotografia
							actual sftipo passe.
						</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
				</Paper>
			</Container>
		</div>
	);
}
