import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps }  from '@mui/material/Alert';
import { PopupType } from '../../utils/PopupType';


export interface IProps {
  type: any,
  content: string,
  flag: boolean,
  duration ?: number
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Popup({type, content, flag, duration}: IProps) {
  const [open, setOpen] = React.useState(flag);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar 
      anchorOrigin={
        type === PopupType.SUCCESS ? 
        { vertical: 'top', horizontal: 'center' } :
        { vertical: 'bottom', horizontal: 'center' }
      }
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert 
        onClose={handleClose}
        severity={type}
        sx={{ width: '100%', fontSize: '1.8rem' }}
      >
        {content}
      </Alert>
    </Snackbar>
  )
}