import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Container } from '@mui/material';
import style from './hero.module.scss';
import { Link } from 'react-router-dom';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Hero = () => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);

	const [news, setNews] = useState([
		{
			title: 'Agora pode fazer o seu agendamento online',
			filePath: 'uploads/news/scheduling_bg.jpg',
			message: 'Efetue o seu agendamento de forma fácil, rápida e cómoda!',
		},
	]);

	let URL_NEWS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;

	useEffect(() => {
		const fetchNews = async () => {
			try {
				const response = await axios.get(
					URL_NEWS +
						'?category=DEST&size=5&orderColumn=creationDate&direction=DESC&v=' +
						new Date().valueOf(),
					{
						headers: { 'x-client-id': getEncryptedClientHeaderInfo() },
					}
				);
				let list = [news[0], ...response.data.content];
				setNews(list);
			} catch (err) {
				console.log(err);
			}
		};

		fetchNews();
	}, []);

	if (!news) return <div />;

	const maxSteps = news.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<div className='brand-bg' style={{ padding: '24px 0 32px' }}>
			<Container>
				<h1>Destaques</h1>

				<Box>
					<AutoPlaySwipeableViews
						axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
						index={activeStep}
						interval={5000}
						onChangeIndex={handleStepChange}
						enableMouseEvents
					>
						{news.map((step, index) => (
							<div
								key={'slides-destaques' + step.id}
								className={style.slidesDestaquesContainer}
							>
								{Math.abs(activeStep - index) <= 2 ? (
									<Card
										sx={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											background: `linear-gradient(0deg, hsla(354, 100%, 31%, 0.6), hsla(354, 100%, 31%, 0.3)), url(${process.env.REACT_APP_URL_API_DOMAIN}${news[activeStep].filePath})`,
											backgroundPosition: 'center',
											backgroundSize: 'cover',
											backgroundRepeat: 'no-repeat',
											p: '8px',
										}}
									>
										<CardContent className={style.sliderDestaquesView}>
											<IconButton
												size='large'
												onClick={handleBack}
												disabled={activeStep === 0}
												className={style.controls}
											>
												{theme.direction === 'rtl' ? (
													<KeyboardArrowRight />
												) : (
													<KeyboardArrowLeft />
												)}
											</IconButton>
											<div style={{ flexGrow: 1 }}>
												<Box
													sx={{
														display: 'block',
														margin: '16px',
													}}
												>
													<Link
														to={
															activeStep === 0
																? '/servicos/efetuar-agendamento'
																: '/noticias/' + news[activeStep].id
														}
														style={{ textDecoration: 'none' }}
													>
														<h4 className={style.heroTitle}>
															{news[activeStep].title}
														</h4>
													</Link>

													<p className={style.heroCaption}>
														{news[activeStep].message}
													</p>
													<Button
														variant='contained'
														size='small'
														className={style.Button}
													>
														<Link
															to={
																activeStep === 0
																	? '/servicos/efetuar-agendamento'
																	: '/noticias/' +
																	  news[activeStep].id
															}
															style={{
																textDecoration: 'none',
																color: '#FFF',
															}}
														>
															{activeStep === 0
																? 'Efetuar agendamento'
																: 'Ver notícia'}
														</Link>
													</Button>
												</Box>
											</div>
											<IconButton
												size='large'
												onClick={handleNext}
												disabled={activeStep === maxSteps - 1}
												className={style.controls}
											>
												{theme.direction === 'rtl' ? (
													<KeyboardArrowLeft />
												) : (
													<KeyboardArrowRight />
												)}
											</IconButton>
										</CardContent>
									</Card>
								) : null}
							</div>
						))}
					</AutoPlaySwipeableViews>
				</Box>
				<MobileStepper
					steps={maxSteps}
					position='static'
					activeStep={activeStep}
					sx={{ justifyContent: 'center', background: 'transparent' }}
				/>
			</Container>
		</div>
	);
};

export default Hero;
