import React from "react";

export default function DataInfo({label, content}) {
  return (
    <React.Fragment>
      <p style={{ mt: 2, color: '#908E9B', fontSize: '1.8rem' }} >
          {label} <br/>
          <span style={{color: '#565360'}}>
          {content}
          </span>
      </p>
    </React.Fragment>
  );
}
