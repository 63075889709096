import { Container, CardMedia, CardContent, CardActions, Card, Button, Grid } from '@mui/material';
import DateBadge from '../../components/ui/DateBadge';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import style from '../../assets/css/lastnews.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getFormattedFullDate, getDateDayMonth } from '../../utils/DateUtils';
import { Link } from 'react-router-dom';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';

export default function LastNews() {
	const [news, setNews] = useState([]);

	let URL_NEWS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;

	useEffect(() => {
		const fetchNews = async () => {
			try {
				const response = await axios.get(
					URL_NEWS +
						'?size=4&orderColumn=creationDate&direction=DESC&v=' +
						new Date().valueOf(),
					{
						headers: { 'x-client-id': getEncryptedClientHeaderInfo() },
					}
				);
				let catList = response.data.content.map((nws) => nws.newsCategory.description);
				catList = catList.filter((el, index) => catList.indexOf(el) === index);
				setNews(response.data.content);
			} catch (err) {
				console.log(err);
			}
		};

		fetchNews();
	}, []);
	return (
		<Container sx={{ marginTop: '24px', marginBottom: '24px' }}>
			<h1>Notícias</h1>

			<Grid container spacing={2}>
				{news?.length > 0 &&
					news.map((item, index) => (
						<Grid item xs={12} sm={6} md={3} key={index}>
							<Card
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
								}}
							>
								<CardMedia
									sx={{ height: 240 }}
									image={
										item.filePath
											? `${process.env.REACT_APP_URL_API_DOMAIN}${item.filePath}`
											: ''
									}
									title='atendimento ao publico'
								/>

								<DateBadge date={getDateDayMonth(item.creationDate)} />
								<span className={style.newsCategory}>
									{item.newsCategory.description}
								</span>
								<CardContent sx={{ flexGrow: 1 }}>
									<Link to={'/noticias/' + item.id}>
										<h4 className={style.newsHead}>{item.title}</h4>
									</Link>
									<p className={style.newsCaption}>{item.message}</p>
								</CardContent>
								<CardActions sx={{ position: 'relative' }}>
									<span className={style.newsDate}>
										<CalendarTodayIcon />
										{getFormattedFullDate(item.creationDate)}
									</span>
									<Link to={'/noticias/' + item.id}>
										<Button
											size='small'
											variant='outlined'
											endIcon={<ChevronRightIcon />}
											className={style.newsBtn}
										>
											Ler notícia
										</Button>
									</Link>
								</CardActions>
							</Card>
						</Grid>
					))}
			</Grid>
			{news?.length > 1 && (
				<div
					style={{ position: 'relative', width: '100%', height: '50px', padding: '5px' }}
				>
					<Link to='/noticias'>
						<span className={style.moreNewsBtn}>
							Ver mais <ChevronRightIcon />
						</span>
					</Link>
				</div>
			)}
		</Container>
	);
}
