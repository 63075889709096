import { Alert, AlertTitle, Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

export default function Identificacao() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Identificação</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h2>Bilhete de Identidade</h2>
					<p>
						Documento nacional de identificação.
						<br /> Sendo biométrico, carece da presença física do cidadão interessado
						para a sua emissão.
					</p>
					<h2 style={{marginTop: '60px'}}>Registo Criminal</h2>
					<p>
						É um documento emitido ao cidadão interessado maior de 16 anos de idade, com
						o objectivo de reconhecer ou atestar a ausência de seus antecedentes
						criminais.
					</p>
					<Alert
						variant='outlined'
						severity='info'
						sx={{ fontSize: '14px', marginBottom: '8px', marginTop: '16px' }}
					>
						<AlertTitle sx={{ fontSize: '16px' }}>Actos consular não disponíveis neste serviço.</AlertTitle>
					</Alert>
					<Alert
						variant='outlined'
						severity='info'
						sx={{ fontSize: '14px', marginBottom: '8px' }}
					>
						<AlertTitle sx={{ fontSize: '16px' }}>Contrariamente do pedido de BI que é presencial, o Registo Criminal pode ser
						pedido através de um procurador.</AlertTitle>
					</Alert>
				</Paper>
			</Container>
		</div>
	);
}
