import { Alert, Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Registos() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Registos</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h2>Inscrição Consular</h2>
					<p>
						Registo informático único da identificação de cidadão guineense residente no
						estrangeiro, realizada com base na informação recolhida no passaporte
						apresentado. Em alternativa à apresentação do passaporte, pode ser utilizado
						o bilhete de identidade ou certidão da narrativa completa do registo de
						nascimento e a respectiva cópia do livro ou outro documento de
						identificação, preferencialmente com fotografia. É um ato consular
						voluntário, embora necessário para a prática de atos consulares.
					</p>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Passaporte</li>
						<li>Bilhete de Identidade</li>
						<li>
							Certidão da Narrativa Completa do Registo de Nascimento e a respectiva
							cópia do Livro
						</li>
						<li>
							Outro documento de identificação, preferencialmente com fotografia,
							válidos.
						</li>
					</ul>
					<h3>A quem se destina a inscrição consular?</h3>
					<p>
						A inscrição consular destina-se aos cidadãos guineenses. Um cidadão
						estrangeiro que necessite dos serviços consulares guineenses (ex.: outorga
						de procuração para produzir efeitos na Guiné-Bissau, pedido de visto) pode
						ter de ser registado na base de dados consular. Para isso terá de apresentar
						passaporte ou Cartão de Cidadão.
					</p>
					<h2 style={{marginTop: '60px'}}>Registo de Nascimento por Transcrição</h2>
					<p>
						É feita para os filhos de progenitores ou um dos progenitores guineenses,
						nascidos fora de Guiné-Bissau, mediante a apresentação do Assento de
						Nascimento emitido pelas Autoridades locais portuguesa ou de um outro país
						terceiro onde a Guiné-Bissau não tenha Representação diplomática ou
						consular.
					</p>
					<h3>Quem o pode pedir</h3>
					<p>
						O Registo de Nascimento por Transcrição pode ser pedido para os filhos de
						cidadãos com nacionalidade guineense:
					</p>
					<ul>
						<li>Por ambos ou um dos progenitores, presencialmente.</li>
					</ul>
					<h3>Transcrição de Perfilhação</h3>
					<p>
						Quando o registo de perfilhação ocorrer no estrangeiro, o cidadão pode pedir
						a sua transcrição.
					</p>
					<p>Deve apresentar:</p>
					<ul>
						<li>
							Certidão de nascimento estrangeiro do perfilhado (validade até 6 meses)
						</li>
						<li>Certidão de perfilhação (validade até 6 meses)</li>
						<li>Documento de identificação do perfilhante</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<h2 style={{marginTop: '60px'}}>Certificado de Capacidade Matrimonial</h2>
					<p>
						Para contrair matrimonio no estrangeiro, as instituições destes países para
						organizar os processos, solicitam o certificado de capacidade matrimonial
						passado pelos serviços consulares do país do cidadão, no qual atesta que o
						mesmo tem capacidade e que não o impede de realizar o seu casamento.
					</p>
					<h3>Documento necessários</h3>
					<ul>
						<li>Auto de declaração assinado pelos nubentes</li>
						<li>Documento de identificação dos nubentes</li>
						<li>
							Cópia do Livro do Registo de Nascimento dos nubentes (dispensado ao
							nubente residente no país, no caso de casamento por procuração)
						</li>
						<li>
							Certidão de Nascimento, se o nubente for estrangeiro (validade 3 meses)
						</li>
					</ul>
					<h2 style={{marginTop: '60px'}}>Casamento/Organização do processo/certidão</h2>
					<p>
						Os que pretenderem contrair o casamento devem fazer esta declaração,
						deslocando-se a uma conservatória ou delegação do registo civil onde
						requererá a instauração do processo. A declaração pode ser feita
						pessoalmente ou por intermédio do(a) procurador(a).
					</p>
					<h3>Atenção</h3>
					<p>
						Apenas um dos nubentes pode fazer-se representar por procurador. <br />A
						certidão de casamento pode ser pedida por qualquer cidadão, desde que tenha
						contraído o matrimonio.
					</p>
					<h2 style={{marginTop: '60px'}}>Transcrição de certidão de Óbito</h2>
					<p>
						Quando a morte ocorrer no estrangeiro, as autoridades competentes deste país
						emitem uma certidão na qual atesta o falecimento do cidadão,
						identificando-o, assim como a data, a hora e o local da morte. A transcrição
						da certidão pode ser pedida por qualquer cidadão, devendo para o efeito
						apresentar o seu documento de identificação.
					</p>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<h2 style={{marginTop: '60px'}}>Declaração de confirmação de nacionalidade</h2>
					<h3>Requisitos</h3>
					<ul>
						<li>
							Formulário de pedido de declaração devidamente preenchido e assinado
							pelo requerente.
						</li>
						<li>Passaporte (digital ou fotografia).</li>
						<li>Cartão de Inscrição Consular, válido.</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<Alert
						variant='outlined'
						severity='info'
						color='warning'
						sx={{ fontSize: '14px', marginBottom: '8px', marginTop: '16px' }}
					>
						No caso de dúvidas ou sempre que se entenda necessário, o serviço reserva-se
						ao direito de solicitar a apresentação de outros documentos.
					</Alert>
					<Alert
						variant='outlined'
						severity='info'
						color='warning'
						sx={{ fontSize: '14px', marginBottom: '8px' }}
					>
						Esta declaração é individual e intransmissível.
					</Alert>
				</Paper>
			</Container>
		</div>
	);
}
