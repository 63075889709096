import React, { useEffect, useState } from 'react';
import {
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	Grid,
	Container,
} from '@mui/material';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';

const URL_SERVICES = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_LOVS_SERVICES}`;
const URL_CATEGORIES = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_LOVS_CATEGORIES}`;

function ServiceInformation({
	category,
	service,
	setcategory,
	setservice,
	setcategorydescription,
	setservicedescription,
}) {
	const [categoryList, setCategoryList] = useState([]);
	const [serviceList, setServiceList] = useState([]);

	const reqOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo() },
	};

	useEffect(() => {
		fetch(URL_CATEGORIES, reqOptions)
			.then(async (response) => {
				const isJson = response.headers.get('content-type')?.includes('application/json');
				const data = isJson && (await response.json());

				if (!response.ok) {
					return;
				}
				setCategoryList(data.lovs);
			})
			.catch((e) => {
				console.error('Error', e);
			});

		if (category) {
			getServicesByCategory(category);
		}
	}, []);

	const getServicesByCategory = React.useCallback((serviceId) => {
		setservice('');
		fetch(`${URL_SERVICES}?categoryCode=${serviceId}`, reqOptions)
			.then(async (response) => {
				const isJson = response.headers.get('content-type')?.includes('application/json');
				const data = isJson && (await response.json());

				if (!response.ok) {
					return;
				}
				setServiceList(data.lovs);
			})
			.catch((e) => {
				console.error('Error', e);
			});
	});

	const setCategoryDescription = (categoryCode) => {
		categoryList.map((cat) => {
			if (cat.code === categoryCode) {
				setcategorydescription(cat.description);
			}
		});
	};

	const setServiceDescription = (serviceCode) => {
		serviceList.map((serv) => {
			if (serv.code === serviceCode) {
				setservicedescription(serv.name);
			}
		});
	};

	const handleCategory = (e) => {
		e.preventDefault();
		const selectedCategory = e.target.value;
		setcategory(selectedCategory);
		getServicesByCategory(selectedCategory);
		setCategoryDescription(selectedCategory);
	};

	const handleService = (e) => {
		e.preventDefault();
		const selectedService = e.target.value;
		setservice(selectedService);
		setServiceDescription(selectedService);
	};

	return (
		<React.Fragment>
			<Container>
				<h2>Por favor, escolha a categoria e o serviço pretendido.</h2>
				<Grid container spacing={3} sx={{marginTop: '20px'}}>
					<Grid item xs={12} sm={6} marginBottom={3}>
						<FormControl variant='standard' fullWidth sx={{ minWidth: 120 }}>
							<InputLabel id='category-label' style={{ fontSize: '1.8rem' }}>
								Categoria
							</InputLabel>
							<Select
								labelId='category-label'
								id='category'
								name='category'
								value={category}
								onChange={handleCategory}
								label='Categoria'
								style={{ fontSize: '1.8rem' }}
							>
								{categoryList?.map((category) => (
									<MenuItem
										value={category.code}
										key={category.code}
										style={{ fontSize: '1.8rem' }}
									>
										{category.description}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} marginBottom={3}>
						<FormControl variant='standard' fullWidth sx={{ minWidth: 120 }}>
							<InputLabel id='service-label' style={{ fontSize: '1.8rem' }}>
								Serviço
							</InputLabel>
							<Select
								labelId='service-label'
								id='service'
								value={service}
								onChange={handleService}
								label='Serviço'
								style={{ fontSize: '1.8rem' }}
							>
								{serviceList?.map((service) => (
									<MenuItem
										value={service.code}
										key={service.code}
										style={{ fontSize: '1.8rem' }}
									>
										{service.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

export default ServiceInformation;
