import { Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

let domain = `${process.env.REACT_APP_URL_WEBSITE}`;

export default function TermosCondicoes() {
    const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Termos e condições de utilização</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h3>1. Aceitação dos termos e condições</h3>
                    <p className='text-justify' style={{marginTop: '20px'}}>
                        Os presentes <strong>Termos e Condições</strong> regulam o acesso e utilização do site <a href={'/'} target='_self' style={{textDecoration: 'none' }}>{domain}</a>, 
                        da responsabilidade do <strong>Consulado Geral da República da Guiné-Bissau no Algarve</strong> (adiante abreviadamente designado por “Consulado”).
                    </p>
                    <p className='text-justify'>
                        Quaisquer questões legais referentes ao site poderão ser consultadas na
                        secção <a href='/privacidade' target='_self' style={{textDecoration: 'none' }}>Política de Privacidade</a>, a qual constitui parte integrante dos presentes <strong>Termos e Condições</strong>.
                    </p>
                    <p className='text-justify'>
                        O acesso e a utilização deste site pressupõem e implicam o 
                        conhecimento e aceitação, pelo utilizador, dos presentes <strong>Termos e Condições</strong>. 
                        Na eventualidade de não pretender ficar vinculado aos presentes <strong>Termos e Condições</strong>, 
                        o utilizador não poderá continuar a aceder e a utilizar o site.
                    </p>
                    <p className='text-justify'>
                        O utilizador declara possuir a capacidade jurídica necessária que lhe permite aceitar os presentes <strong>Termos e Condições</strong>.
                    </p>
                    <p className='text-justify'>
                        O Consulado poderá alterar ou atualizar, no todo ou em parte, 
                        os presentes <strong>Termos e Condições</strong>, bem como a <a href='/privacidade' target='_self' style={{textDecoration: 'none' }}>Política de Privacidade</a>.
                        Quaisquer alterações ou atualizações dos presentes <strong>Termos e Condições</strong>, 
                        bem como da <a href='/privacidade' target='_self' style={{textDecoration: 'none' }}>Política de Privacidade</a>, entrarão em vigor logo que publicados na 
                        respetiva secção do site, aconselhando-se o utilizador a consultar regularmente
                        as secções dos <strong>Termos e Condições</strong> e da <a href='/privacidade' target='_self' style={{textDecoration: 'none' }}>Política de Privacidade</a> para se inteirar 
                        das suas versões mais recentes e atualizadas.
                    </p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
                        Os <strong>Termos e Condições</strong> aqui definidos aplicam-se apenas ao uso deste site,
                        não sendo, portanto, aplicáveis a quaisquer sites externos cujos links 
                        sejam fornecidos no presente site. Nos casos em que tal sucede ou venha a suceder, 
                        os links são fornecidos com o propósito de facilitar o acesso a 
                        informações adicionais e para conveniência do utilizador.
                    </p>
					<h3>2. Autorização de acesso e utilização</h3>
					<p className='text-justify'>    
						O Consulado autoriza qualquer utilizador a aceder 
                        e utilizar este site apenas para efeitos 
                        informativos ou de contacto e de agendamento de serviços consulares.
                    </p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
                        Desde que respeite os presentes <strong>Termos e Condições</strong> e a legislação aplicável, 
                        particularmente a proteção dos direitos de autor e da propriedade industrial e as leis penais,
                        o utilizador goza de inteira liberdade de utilização do site.
                    </p>
					
					<h3>3. A conduta a ser adotada pelo utilizador do site</h3>
                    <p className='text-justify'>
                        O utilizador deve utilizar este site com responsabilidade, não deve comprometer a continuidade, 
                        integridade e qualidade dos seus conteúdos e funcionalidades, 
                        nem interferir com os direitos e/ou a capacidade de outros utilizadores de utilizar este site.
                    </p>
                    <p className='text-justify'>
                        É expressamente proibido utilizar este site com o objetivo de disponibilizar 
                        ou partilhar qualquer conteúdo ilegal.
                    </p>
                    <p className='text-justify'>
                        Não é permitida a publicação de qualquer informação ou conteúdo pertencentes a terceiros, 
                        ou sobre os quais o utilizador não detenha os direitos de uso, como, por exemplo, 
                        conteúdo protegido por direito de autor de terceiros 
                        ou conteúdo que contenha dados pessoais de terceiros.
                    </p>
                    <p className='text-justify'>
                        É expressamente proibido utilizar o site para fornecer ou transferir qualquer tipo de material, 
                        arquivos ou programas que possam interromper, destruir ou limitar 
                        a funcionalidade do site ou de qualquer equipamento.
                    </p>
                    <p className='text-justify' style={{marginBottom: '60px'}}>
                        Para obter informação sobre a forma como o Consulado trata os dados pessoais recolhidos, 
                        o utilizador deve consultar a <Link to='/privacidade' target='_self' style={{textDecoration: 'none' }}>Política de Privacidade</Link>. 
                    </p>
					
					<h3>4. Propriedade intelectual</h3>
                    <p className='text-justify'>
                        Este site, bem como todos os elementos nele presentes, nomeadamente textos, 
                        imagens, vídeos, logótipos e símbolos apresentados (“conteúdos”), são propriedade do Consulado 
                        ou os respetivos direitos terão sido previamente licenciados ou cedidos ao Consulado.  
                    </p>
                    <p className='text-justify'>
                        O utilizador não tem o direito de usar os conteúdos do presente site para 
                        quaisquer fins que não os previstos nos presentes Termos e Condições.   
                    </p>
                    <p className='text-justify'>
                        O site e respetivos conteúdos encontram-se protegidos por direitos de propriedade 
                        intelectual (direitos de autor ou direitos de propriedade industrial), direitos de personalidade, 
                        como o direito de imagem, e respetiva legislação aplicável, sendo o utilizador obrigado a respeitá-los. 
                    </p>
                    <p className='text-justify' style={{marginBottom: '60px'}}>
                        O utilizador pode, contudo, imprimir excertos dos conteúdos presentes neste site, 
                        desde que não os modifique, que sejam destinados a uso pessoal ou de interesse 
                        público não comercial, que contenham uma referência à sua fonte, e desde que qualquer 
                        outra indicação de propriedade ou direitos de propriedade intelectual não sejam removidos. 
                    </p>


                    <h3>5. Exoneração de responsabilidade</h3>
                    <p className='text-justify'>
                        O utilizador é exclusivamente responsável pela utilização que fizer de 
                        todos os conteúdos disponíveis no ou a partir deste site, de acordo com os presentes Termos e Condições.   
                    </p>
                    <p className='text-justify'>
                        O Consulado envidará todos os esforços para disponibilizar 
                        neste site informação atualizada, abrangente e precisa.  
                    </p>
                    <p className='text-justify'>
                        No entanto, o Consulado não poderá ser responsabilizado por quaisquer prejuízos ou danos 
                        em sede de responsabilidade civil (incluindo, mas sem que a estes estejam limitados, 
                        danos emergentes, lucros cessantes e danos morais, causados direta ou indiretamente), 
                        que surjam em consequência da utilização, correta ou incorreta do seu site e respetivos 
                        conteúdos por parte do utilizador, incluindo o acesso indevido ao computador e 
                        sistema informático do utilizador por terceiros.
                    </p>
                    <p className='text-justify'>
                        A informação divulgada deve ser encarada na sua vertente informativa.  
                    </p>
                    <p className='text-justify'>
                        Adicionalmente, este site contém ou pode conter ligações para sites operados por entidades 
                        terceiras sobre os quais o Consulado não tem controlo e pelos quais não assume qualquer responsabilidade.   
                    </p>
                    <p className='text-justify' style={{marginBottom: '60px'}}>
                        A visualização de disposições legais neste site não dispensa a consulta das normas legais em vigor, 
                        aprovadas oficialmente, publicadas nas edições e suportes originais (nomeadamente o Boletim Oficial ou Diário da República).  
                    </p>


                    <h3>6. Exclusão de garantias</h3>
                    <p className='text-justify'>
                        O Consulado não pode garantir o acesso ininterrupto, seguro ou isento de erros a este site, 
                        ou que eventuais erros sejam atempadamente corrigidos.    
                    </p>
                    <p className='text-justify'>
                        O utilizador aceita que qualquer material ou informação obtidos serão usados por sua 
                        própria conta e risco e que é responsável por qualquer dano que possa ser causado ao 
                        sistema operativo ou software do dispositivo utilizado para aceder ao presente site 
                        ou por qualquer perda de dados que possa resultar do download de conteúdos ou informações constantes no site.
                    </p>
                    <p className='text-justify' style={{marginBottom: '60px'}}>
                        O utilizador é o único responsável por garantir a proteção dos seus dispositivos 
                        contra ameaças resultantes de quaisquer vírus e outras falhas de segurança. 
                    </p>

                    <h3>7. Contacto</h3>
                    <p className='text-justify' style={{marginBottom: '60px'}}>
                        Caso o utilizador tenha alguma dúvida ou necessite de alguma informação adicional 
                        sobre os presentes Termos e Condições, poderá contactar o Consulado através dos seguintes pontos de contactos:
                        <ul style={{marginTop: 10 + 'px', textAlign: 'left'}}>
                            <li>Correio postal: <strong>Avenida da Liberdade n.º 131, Escritório 8, 8200-002 Albufeira</strong></li>
                            <li>Correio eletrónico: <strong>consuladogeralgbalgarve.pt@gmail.com</strong></li>
                        </ul>
                    </p>

                    <p style={{textAlign: 'left'}}>
                        Data da última atualização: 25.11.2023
                    </p>
				</Paper>
			</Container>
		</div>
	);
}
