import { Box, CardContent, Container, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import exampleImg from '../../assets/images/portfolio/07-large.jpg';
import { useEffect, useState } from 'react';

export default function EmergenciaConsular() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Emergência consular</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<Card sx={{ display: 'flex' }} elevation={0}>
						<CardMedia
							component='img'
							sx={{ width: 200, display: { xs: 'none', sm: 'block', md: 'block' } }}
							image={exampleImg}
							alt='emergencia_consular'
						/>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<CardContent sx={{ flex: '1 0 auto' }}>
								<h3 style={{ marginBottom: '8px' }}>
									Em caso de emergência, por favor, contactar
								</h3>
								<p>
									<a
										href='tel:+351961941934'
										style={{
											fontVariantNumeric: 'tabular-nums',
											textDecoration: 'none',
											color: '#444',
											fontSize: '2rem',
										}}
									>
										<PhoneIphoneIcon sx={{fontSize: '2.2rem'}} /> 00351 961 941 934
									</a>
								</p>
							</CardContent>
						</Box>
					</Card>
				</Paper>
			</Container>
		</div>
	);
}
