import React from "react";

export default function DataSection({title}) {
  return (
    <React.Fragment>
      <h4  style={{ mt: '5px', marginBottom: '10px', fontWeight: '600', fontSize: '' }}>
          {title}
      </h4>
    </React.Fragment>
  );
}
