import '@fontsource-variable/inter/index.css';
import '../src/assets/css/global.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import PageNotFound from './pages/common/PageNotFound';
import HomePage from './pages/common/HomePage';
import NewScheduling from './pages/scheduling/NewScheduling';
import { CssBaseline } from '@mui/material';

import SmoothScroll from 'smooth-scroll';
import TopNav from './pages/common/TopNav';
import TopHeader from './pages/common/TopHeader';
import MainMenu from './components/ui/MainNav';
import EmergenciaConsular from './pages/common/EmergenciaConsular';
import Footer from './components/footer/footer';
import PageLocation from './components/ui/PageLocation';
import Noticias from './pages/common/Noticias';
import VerNoticia from './pages/common/VerNoticia';
import Registos from './pages/common/Registos';
import Notariado from './pages/common/Notariado';
import Identificacao from './pages/common/Identificacao';
import Migracao from './pages/common/Migracao';
import Privacidade from './pages/common/Privacidade';
import TermosCondicoes from './pages/common/TermosCondicoes';
import Formularios from './pages/common/Formularios';
import MensagemConsul from './pages/common/MensagemConsul';
import Emolumentos from './pages/common/Emolumentos';
import CookiePopup from './pages/common/CookiePopup';
import Contactos from './pages/common/Contactos';
import Servicos from './pages/common/Servicos';

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

const theme = (theme) =>
	createTheme({
		...theme,
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						fontSize: '1.843rem',
						lineHeight: 1.4,
						fontWeight: 400,
						fontFamily: "'Inter Variable', sans-serif",
						backgroundColor: 'hsla(0, 43%, 99%, 1)',
					},
				},
			},
		},
		palette: {
			primary: {
				main: '#9C0010',
			},
			secondary: {
				main: green[500],
			},
		},
		typography: {
			fontFamily: "'Inter Variable', sans-serif",
		},
	});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<TopNav />
				<TopHeader />
				<MainMenu />
				<PageLocation />
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/noticias' element={<Noticias />} />
					<Route path='/noticias/:id' element={<VerNoticia />} />
					<Route path='/emergencia-consular' element={<EmergenciaConsular />} />
					<Route path='/termos' element={<TermosCondicoes />} />
					<Route path='/privacidade' element={<Privacidade />} />
					<Route path='/servicos' element={<Servicos />} />
					<Route path='/servicos/registos' element={<Registos />} />
					<Route path='/servicos/notariado' element={<Notariado />} />
					<Route path='/servicos/identificacao' element={<Identificacao />} />
					<Route path='/servicos/migracao' element={<Migracao />} />
					<Route path='/servicos/formularios' element={<Formularios />} />
					<Route path='/servicos/efetuar-agendamento' element={<NewScheduling />} />
					<Route path='/servicos/emolumentos' element={<Emolumentos />} />
					<Route path='/mensagem-consul' element={<MensagemConsul />} />
					<Route path='/contactos' element={<Contactos />} />
					<Route path='*' element={<PageNotFound />} />
				</Routes>
				<Footer />
				<CookiePopup />
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
