import React, { useEffect, useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Alert, AlertTitle, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import Review from './Review';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonalInformation from './PersonalInformation';
import ServiceInformation from './ServiceInformation';
import HourAndDataInformation from './HourAndDataInformation';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import DataInfo from '../../components/ui/DataInfo';
import DataSection from '../../components/ui/DataSection';
import { useNavigate } from 'react-router';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import Recaptcha from '../common/Recaptcha';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';
import Popup from '../../components/ui/Popup';
import { PopupType } from '../../utils/PopupType';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import labels from '../../utils/Labels.json';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const steps = ['Dados pessoais', 'Serviço', 'Data e hora', 'Confirmação'];

const theme = createTheme({
	palette: {
		primary: {
			main: '#9C0010',
		}
	},
});

function NewScheduling() {
	const [activeStep, setActiveStep] = useState(0);
	const [open, setOpen] = useState(false);

	const [fullName, setFullName] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [email, setEmail] = useState('');
	const [emailConfirmation, setEmailConfirmacion] = useState('');
	const [category, setCategory] = useState('');
	const [service, setService] = useState('');
	const [date, setDate] = useState();
	const [hour, setHour] = useState('');
	const [checked, setChecked] = useState(false);
	const [categoryDescription, setCategoryDescription] = useState('');
	const [serviceDescription, setServiceDescription] = useState('');
	const [dateParam, setDateParam] = useState('');

	const [isError, setIsError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isMobile, setIsMobile] = useState(false);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
	const [confirmationEmailDifferent, setConfirmationEmailDifferent] = useState(false);
	const [isInvalidFullName, setIsInvalidFullName] = useState(false);
	const [isServerEmailError, setServerEmailError] = useState(false);
	const recaptchaRef = useRef();

	const navigate = useNavigate();

	const enableButton = () => {
		let status =
			(activeStep === 0 &&
				fullName?.trim() !== '' &&
				mobileNumber !== '' &&
				email?.trim() !== '' &&
				emailConfirmation?.trim() !== '' &&
				email === emailConfirmation &&
				!isInvalidEmail &&
				!isInvalidFullName &&
				!isInvalidPhoneNumber &&
				!confirmationEmailDifferent) ||
			(activeStep === 1 && category?.trim() !== '' && service?.trim() !== '') ||
			(activeStep === 2 && date !== undefined && hour?.trim() !== '') ||
			(activeStep === 3 && checked);

		return status;
	};

	const handleChange = (value) => {
		setChecked(value);
	};

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;

			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);



	const handleEmail = (newState) => {
		setEmail(newState);
	}
	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<PersonalInformation
						fullname={fullName}
						setfullname={setFullName}
						mobilenumber={mobileNumber}
						setmobilenumber={setMobileNumber}
						email={email}
						setemail={handleEmail}
						emailconfirmation={emailConfirmation}
						setemailconfirmation={setEmailConfirmacion}
						isinvalidfullname={isInvalidFullName}
						setisinvalidfullname={setIsInvalidFullName}
						isinvalidphonenumber={isInvalidPhoneNumber}
						setisinvalidphonenumber={setIsInvalidPhoneNumber}
						isinvalidemail={isInvalidEmail}
						setisinvalidemail={setIsInvalidEmail}
						isinvalidconfirmationemail={confirmationEmailDifferent}
						setisinvalidconfirmationemail={setConfirmationEmailDifferent}
					/>
				);
			case 1:
				return (
					<ServiceInformation
						category={category}
						service={service}
						setcategory={setCategory}
						setservice={setService}
						categorydescription={categoryDescription}
						setcategorydescription={setCategoryDescription}
						servicedescription={serviceDescription}
						setservicedescription={setServiceDescription}
					/>
				);
			case 2:
				return (
					<HourAndDataInformation
						date={date}
						setdate={setDate}
						hour={hour}
						sethour={setHour}
						category={category}
						service={service}
						setdateparam={setDateParam}
					/>
				);
			case 3:
				return (
					<Review
						fullname={fullName}
						mobilenumber={mobileNumber}
						email={email}
						date={date}
						hour={hour}
						handlechange={handleChange}
						categorydescription={categoryDescription}
						servicedescription={serviceDescription}
						ismobile={isMobile}
						checked={checked}
					/>
				);
			default:
				throw new Error('Unknown step');
		}
	}


	const resetForm = () => {
		setActiveStep(0);
		setOpen(false);
		setFullName('');
		setMobileNumber('');
		setEmail('');
		setEmailConfirmacion('');
		setCategory('');
		setService('');
		setDate('');
		setHour('');
		setChecked(false);
		setCategoryDescription('');
		setServiceDescription('');
		setDateParam('');
		setIsError(false);
		setLoading(false);
		setErrorMessage('');
		setIsInvalidEmail(false);
		setIsInvalidPhoneNumber(false);
		setConfirmationEmailDifferent(false);
		setIsInvalidFullName(false);
		setServerEmailError(false);
	}

	const URL_SCHEDULING = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_SCHEDULINGS}`;

	const requestOptions = {
		headers: {'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo() },
	};

	const newScheduling = async (data) => {
		try {

			setLoading(true);
			setErrorMessage('');
			setIsError(false);
	
			const response = await axios.post(URL_SCHEDULING, JSON.stringify(data), requestOptions);
			if (response?.status !== HttpResponseStatus.OK) {
				return handleError(response);
			}
			setLoading(false);
			setIsError(false);
			nextStep();
	
			} catch (error) {
				setLoading(false);
				setIsError(true);
				handleError(error?.response);
				console.error('There was an error!', error);
			}
	}

	const handleNext = (e) => {
		e.preventDefault();
		if (activeStep === 3 && checked) {
			const request = {
				citizenFullName: fullName,
				citizenEmail: email,
				citizenMobileNumber: mobileNumber,
				schedulingDate: dateParam,
				schedulingHour: hour,
				categoryCode: category,
				serviceCode: service,
			};

			newScheduling(request);
		} else {
			nextStep();
		}
		handleFormSubmit();
	};

	const handleFormSubmit = async () => {
		// Trigger reCAPTCHA verification
		const recaptchaValue = await recaptchaRef.current.executeAsync();

	};

	const handleError = (response) => {
		setLoading(false);
		setIsError(true);
		setServerEmailError(false);
		if (response) {
			if (response.status === HttpResponseStatus.SERVER_EMAIL_ERROR) {
				setServerEmailError(true);
				setIsError(false);
				nextStep();
			} else {
				const errors = extractErrorMessagesFromResponse(response);
				setErrorMessage(errors?.join('; '));
			}
		} else {
			setErrorMessage(labels.common_generic_error);
		}
		return;
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const redirectToHome = () => {
		navigate('/');
	};


	const nextStep = () => {
		setActiveStep((previous) => previous + 1);
	}

	return (
		<>
			<CssBaseline />
			<div className='brand-bg' style={{ padding: '24px 0' }}>
				<Container>
					<h1>Efetuar agendamento</h1>

					{!isMobile && activeStep !== steps.length && (
						<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={10}>
							<Stepper activeStep={activeStep} sx={{ pt: 1, pb: 1 }}>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel
											sx={{ '&.MuiStepLabel-label': { fontSize: '1.2rem' }, '.muisteplabel-labelcontainer span': {fontSize: '1.6rem'} }}
										>
											{label}
										</StepLabel>
									</Step>
								))}
							</Stepper>
						</Paper>
					)}
					<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={10}>
						{activeStep === steps.length ? (

							!isServerEmailError ?
							<React.Fragment>
								<h3 style={{marginBottom: '20px'}}>
									O seu agendamento foi efetuado com sucesso!
								</h3>
								<p>
									A confirmação e os detalhes do seu agendamento foram enviados
									para o email: {email}.
								</p>
								<p>
									Caso não tenha recebido o email da confirmação do agendamento,
									por favor, verifique a pasta "lixo electrónico/spam" da sua caixa de correio.
								</p>
								<Alert
									variant='outlined'
									severity='warning'
									sx={{ marginBottom: '40px', marginTop: '4px' }}
								>
									<AlertTitle sx={{ fontSize: '1.6rem' }}>
										No dia do seu agendamento, por favor, traga consigo todos os
										documentos solicitados para o acto consular pretendido.
										<br />
										<ul>
											<li>Poderá consultar a informação dos documentos necessários e fazer donwload do formulário pretendido <Link to={'/servicos/formularios'} target='_blank'>aqui</Link>.</li>
											<li>Poderá consultar a informação dos emolumentos/taxas dos serviços <Link to={'/servicos/emolumentos'} target='_blank'>aqui</Link>.</li>
										</ul>
									</AlertTitle>
								</Alert>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<DataSection title={'Endereço do Consulado Geral'} />
											<DataInfo
												label={'Morada'}
												content={
													'Avenida da liberdade nº 131, escritório 8'
												}
											/>
											<DataInfo
												label={'Código postal'}
												content={'8200-002 Albufeira'}
											/>
											<DataInfo
												label={'Contactos'}
												content={'(+351) 289 588 084 | (+351) 961 941 934'}
											/>
											<DataInfo
												label={'Email'}
												content={'geral@cgrgba.gw'}
											/>
										</Grid>
									</Grid>
								</Box>
							</React.Fragment> :

							<React.Fragment>
								<h3 style={{marginBottom: '20px'}}>
									O seu agendamento foi efetuado com sucesso!
								</h3>
								<p>
									Seguem os detalhes do agendamento:
								</p>
								<DataInfo
									label={'Categoria'}
									content={categoryDescription}
								/>
								<DataInfo
									label={'Serviço'}
									content={serviceDescription}
								/>
								<DataInfo
									label={'Data'}
									content={date}
								/>
								<DataInfo
									label={'Hora'}
									content={hour}
								/>
								<Alert
									variant='outlined'
									severity='warning'
									sx={{ marginBottom: '40px', marginTop: '40px' }}
								>
									<AlertTitle sx={{ fontSize: '1.6rem' }}>
										No dia do seu agendamento, por favor, traga consigo todos os
										documentos solicitados para o acto consular pretendido.
										<br />
										<ul>
											<li>Poderá consultar a informação dos documentos necessários e fazer donwload do formulário pretendido <Link to={'/servicos/formularios'} target='_blank'>aqui</Link>.</li>
											<li>Poderá consultar a informação dos emolumentos/taxas dos serviços <Link to={'/servicos/emolumentos'} target='_blank'>aqui</Link>.</li>
										</ul>
									</AlertTitle>
								</Alert>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<DataSection title={'Endereço do Consulado Geral'} />
											<DataInfo
												label={'Morada'}
												content={
													'Avenida da liberdade nº 131, escritório 8'
												}
											/>
											<DataInfo
												label={'Código postal'}
												content={'8200-002 Albufeira'}
											/>
											<DataInfo
												label={'Contactos'}
												content={'(+351) 289 588 084 | (+351) 961 941 934'}
											/>
											<DataInfo
												label={'Email'}
												content={'geral@cgrgba.gw'}
											/>
										</Grid>
									</Grid>
								</Box>
							</React.Fragment>

						) : (
							<React.Fragment>
								{isMobile ? (
									<>
										<Box sx={{ flexGrow: 1 }}>
											<Paper
												square
												elevation={0}
												sx={{
													display: 'flex',
													alignItems: 'center',
													pl: 2,
													bgcolor: 'background.default',
												}}
											>
												<Typography>{steps[activeStep].label}</Typography>
											</Paper>
											<Box sx={{ width: '100%', mb: 5 }}>
												{getStepContent(activeStep)}
											</Box>
											<MobileStepper
												variant='text'
												steps={steps.length}
												position='static'
												activeStep={activeStep}
												nextButton={
													<Button
														size='small'
														onClick={handleNext}
														sx={{
															fontSize: '1.8rem',
															textTransform: 'none',
														}}
														disabled={!enableButton() || loading}
													>
														{activeStep === steps.length - 1
															? 'Submeter'
															: 'Continuar'}
														{theme.direction === 'rtl' ? (
															<KeyboardArrowLeft />
														) : (
															<KeyboardArrowRight />
														)}
													</Button>
												}
												backButton={
													activeStep !== 0 ? (
														<Button
															onClick={handleBack}
															sx={{
																fontSize: '1.8rem',
																textTransform: 'none',
															}}
														>
															{theme.direction === 'rtl' ? (
																<KeyboardArrowRight />
															) : (
																<KeyboardArrowLeft />
															)}
															Voltar
														</Button>
													) : (
														<Button
															onClick={handleClickOpen}
															sx={{
																fontSize: '1.8rem',
																textTransform: 'none',
															}}
														>
															{theme.direction === 'rtl' ? (
																<KeyboardArrowRight />
															) : (
																<KeyboardArrowLeft />
															)}
															Cancelar
														</Button>
													)
												}
											/>
										</Box>
									</>
								) : (
									<>
										{getStepContent(activeStep)}
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
											padding={3}
										>
											{activeStep !== 0 && (
												<Button
													onClick={handleBack}
													sx={{
														mt: 3,
														height: 40,
														textTransform: 'none',
														fontSize: '1.8rem',
													}}
													startIcon={<KeyboardArrowLeft />}
												>
													Voltar
												</Button>
											)}
											{activeStep === 0 && (
												<Button
													onClick={handleClickOpen}
													sx={{
														mt: 3,
														height: 40,
														textTransform: 'none',
														fontSize: '1.8rem',
													}}
													startIcon={<KeyboardArrowLeft />}
												>
													Cancelar
												</Button>
											)}

											<Button
												size='medium'
												variant='contained'
												onClick={handleNext}
												sx={{
													mt: 3,
													ml: 1,
													mb: 2,
													height: 40,
													textTransform: 'none',
													fontSize: '1.8rem',
												}}
												disabled={!enableButton() || loading}
												endIcon={<KeyboardArrowRight />}
											>
												{activeStep === steps.length - 1
													? 'Submeter'
													: 'Continuar'}
											</Button>
										</Box>
									</>
								)}

								<Dialog
									fullScreen={false}
									open={open}
									onClose={handleClose}
									aria-labelledby='responsive-dialog-title'
								>
									<DialogTitle sx={{fontSize: '1.8rem' }} id='responsive-dialog-title'>
										{'Deseja cancelar esta operação?'}
									</DialogTitle>
									<DialogContent>
										<DialogContentText sx={{fontSize: '1.8rem' }}>
											Ao clicar em 'Sair' irá perder todos os dados
											introduzidos. Deseja sair?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={redirectToHome}
											autoFocus
											sx={{ textTransform: 'none', fontSize: '1.8rem' }}
										>
											Sim, desejo sair
										</Button>
										<Button
											autoFocus
											onClick={handleClose}
											sx={{ textTransform: 'none', fontSize: '1.8rem' }}
										>
											Continuar
										</Button>
									</DialogActions>
								</Dialog>
							</React.Fragment>
						)}
					</Paper>
					{ isError &&
						<Popup 
							type={PopupType.ERROR}  
							content={errorMessage}
							flag={isError}
							duration={10000}
						/>
					}

				{activeStep === steps.length  && 
					<Grid item
						sx={{ 
							display: isMobile ? 'normal' : 'flex',
							justifyContent: 'space-between',
							textAlign: 'center',
							marginBottom: '60px'
						}}
						xs={12} sm={12} md={6}
					>
						<Button
							onClick={redirectToHome}
							sx={{
								textTransform: 'none',
								fontSize: '1.8rem',
								marginBottom: '10px'
							}}
							startIcon={<KeyboardArrowLeft />}
						>
							Voltar à página inicial
						</Button>					
						<Button
							size='medium'
							variant='contained'
							onClick={() => resetForm()}
							sx={{
								height: 'auto',
								textTransform: 'none',
								fontSize: '1.8rem',
							}}
							startIcon={<AddIcon />}
						>
							Efetuar novo agendamento
						</Button>
					</Grid>
				}
				</Container>
				<Recaptcha recaptchaRef={recaptchaRef} />
			</div>
		</>
	);
}

export default NewScheduling;
