import { Container, Paper } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import style from '../../assets/css/contatenos.module.scss';
import classNames from 'classnames/bind';
import EmergenciaConsular from './EmergenciaConsular';
import { useEffect, useState } from 'react';

export default function Contactos() {

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const sc = classNames.bind(style);
	return (
		<div id='contacteNos' className={sc('contacteNos', 'brand-bg')}>
			<Container sx={{marginBottom: '60px'}}>
				<h1>Contactos</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '24px 5px 60px', margin: '24px 0' }}>
				<div className={style.contactosWrapper}>
					<div className={sc('linksColumn')}>
						<h3>Morada</h3>
						<a
							href='https://www.google.pt/maps/place/@37.0917861,-8.2554155?q=Consulado+Geral+da+Guiné+Bissau'
							className={style.oddOne}
						>
							<LocationOnIcon />
							Avenida da Liberdade Nº 131 <br />
							Escritório 8, 8200-002 Albufeira
						</a>
					</div>
					<div className={style.linksColumn}>
						<h3>Contactos</h3>
						<a href='tel:+351961941934'>
							<PhoneIphoneIcon />
							00351 961 941 934
						</a>

						<a href='tel:+351289588084'>
							<PhoneIcon />
							00351 289 588 084
						</a>

						<a href='mailto:geral@cgrgba.gw'>
							<EmailIcon />
							geral@cgrgba.gw
						</a>
					</div>
					<div className={style.linksColumn}>
						<h3>Horário de funcionamento</h3>
						<p>Segunda à Sexta-feira</p>
						<p>Abertura: 09:00 - 17:00</p>
						<p>Atendimento ao público: 09:00 - 15:30</p>
					</div>
				</div>
				</Paper>
			</Container>
			<EmergenciaConsular />
		</div>
	);
}
