import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container } from '@mui/material';
import DownloadButton from '../../components/ui/DownloadButton';
import { useState, useEffect } from 'react';

export default function Formularios() {
	const [isMobile, setIsMobile] = useState(false);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: 'hsla(354, 100%, 31%, 1)',
			color: theme.palette.common.white,
			fontSize: '1.8rem',
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: '1.8rem',
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		backgroundColor: 'hsla(0, 43%, 99%, 1)',
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;

			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container sx={{marginBottom: '80px'}}>
				<h1>Formulários</h1>
				<h2>Registos</h2>
				<TableContainer component='div' sx={{ marginBottom: '50px' }}>
					<Table aria-label='registos'>
						<TableHead>
							<TableRow>
								<StyledTableCell>Documento</StyledTableCell>
								{!isMobile && <StyledTableCell> </StyledTableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/CEDULA_INSCRICAO_CONSULAR.pdf' download>
										Pedido de cédula de inscrição consular
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/CEDULA_INSCRICAO_CONSULAR.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/INSCRICAO_CONSULAR.pdf' download>
										Pedido de inscrição consular
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/INSCRICAO_CONSULAR.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/DECLARACAO_REGISTO_CRIANCA.pdf' download>
										Pedido de declaração para registo de criança
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton
											link={'/files/DECLARACAO_REGISTO_CRIANCA.pdf'}
										/>
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/DECLARACAO_CASAMENTO.pdf' download>
										Auto de declaração para casamento
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/DECLARACAO_CASAMENTO.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<h2>Notariado</h2>
				<TableContainer component='div' sx={{ marginBottom: '50px' }}>
					<Table aria-label='notariado'>
						<TableHead>
							<TableRow>
								<StyledTableCell>Documento</StyledTableCell>
								{!isMobile && <StyledTableCell> </StyledTableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/ATESTADO_VIDA.pdf' download>
										Pedido de atestado de vida
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/ATESTADO_VIDA.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/DECLARACAO_TROCA_CARTA_CONDUCAO.pdf' download>
										Pedido de declaração para a troca de carta de condução
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton
											link={'/files/DECLARACAO_TROCA_CARTA_CONDUCAO.pdf'}
										/>
									</StyledTableCell>
								)}
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<h2>Migração</h2>
				<TableContainer component='div' sx={{ marginBottom: '50px' }}>
					<Table aria-label='migração'>
						<TableHead>
							<TableRow>
								<StyledTableCell>Documento</StyledTableCell>
								{!isMobile && <StyledTableCell> </StyledTableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow key={0}>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/PASSAPORTE.pdf' download>
										Pedido de passaporte
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/PASSAPORTE.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow key={0}>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/SALVO_CONDUTO.pdf' download>
										Pedido de salvo conduto
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/SALVO_CONDUTO.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
							<StyledTableRow key={0}>
								<StyledTableCell component='th' scope='row'>
									<a href='/files/VISTO.pdf' download>
										Pedido de visto
									</a>
								</StyledTableCell>
								{!isMobile && (
									<StyledTableCell align='right'>
										<DownloadButton link={'/files/VISTO.pdf'} />
									</StyledTableCell>
								)}
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</div>
	);
}
