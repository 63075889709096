import { Alert, AlertTitle, Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Notariado() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Notariado</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h2>Procurações</h2>
					<p>
						Pode ser solicitado por qualquer cidadão (mandante) presencialmente, na qual
						dá poderes a outra (constituinte) de agir em seu nome. Pode ter um prazo ou
						um período pré-definido e nela deve constar a finalidade do mandato.
					</p>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Documento de identificação do mandante.</li>
						<li>Documento de identificação do constituinte.</li>
						<li>Cartão de inscrição consular válido.</li>
					</ul>
					<Alert
						variant='outlined'
						severity='info'
						sx={{ fontSize: '14px', marginBottom: '8px', marginTop: '16px' }}
					>
						<AlertTitle sx={{ fontSize: '16px' }}>A procuração
						passada no estrangeiro, para ter validade deve ser autenticada no consulado
						do país a que se destina.</AlertTitle>
					</Alert>
					<h2 style={{marginTop: '60px'}}>Autenticação/ Reconhecimento de assinatura</h2>
					<p>
						O reconhecimento consiste na certificação notarial da autoria da assinatura,
						ou da letra e assinatura, aposta em documento particular.
						<br /> Os documentos autênticos passados no estrangeiro na conformidade da
						lei desse país, e os documentos particulares lavrados fora da Guiné-Bissau,
						legalizados por funcionário público estrangeiro, consideram-se legalizados à
						luz da lei guineense, desde que a assinatura do funcionário público esteja
						reconhecida por agente diplomático ou consular guineense no Estado respetivo
						e a assinatura deste agente esteja autenticada com o selo branco consular
						respetivo.
					</p>
					<h2>IMPORTANTE</h2>
					<p>Apesar de existir vários tipos de reconhecimento, este serviço só faz o:</p>
					<ul>
						<li>
							<b>Reconhecimento simples:</b> respeita à assinatura ou à letra e
							assinatura do signatário. É sempre presencial, pelo que só pode ser
							feito em documento assinado, ou escrito e assinado, na presença do
							agente consular.
						</li>
						<li>
							<b>Reconhecimento de assinatura a rogo:</b> quando não é assinado pelo
							seu autor, por o mesmo não poder ou não saber assinar, mas outrem a seu
							rogo. Ambos devem apresentar passaporte ou Título de Residência e cartão
							de inscrição consular válidos.
						</li>
					</ul>
					<h3>Requisitos</h3>
					<ul>
						<li>
							Passaporte ou título de residência, cartão de inscrição consular válido
						</li>
						<li>Documento a legalizar</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
					<h2 style={{marginTop: '60px'}}>Recenseamento Eleitoral</h2>
					<p>
						Acto cívico obrigatório à todos os cidadãos maiores de 18 anos residentes no
						país e no estrangeiro. Compete a comissão de recenseamento devidamente
						constituída a inscrever o cidadão e por uma única vez.
					</p>
					<p>
						<b>OBS:</b> Consulte a Lei Eleitoral nº 2/98 de 23 de abril.
					</p>

					<h2 style={{marginTop: '60px'}}>Carta de Condução</h2>
					<p>
						Acto consular através do qual se emite um documento que tem como objectivo
						provar a validade e a autenticidade da carta de condução de Guiné-Bissau.
						<br />
						Pode pedir esta declaração todo o cidadão portador de carta de condução de
						Guiné-Bissau, residente em Portugal.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Formulário devidamente preenchido, presencialmente.</li>
						<li>Uma fotografia actualizada tipo passe e fundo branco.</li>
						<li>Original e fotocópia da carta de condução válida.</li>
						<li>Original e fotocopia da certidão da carta.</li>
						<li>
							Documento comprovativo em como quando fez a carta este a residir ou de
							férias na Guiné-Bissau (Ex.: Passaporte com carimbos de entrada e saída,
							etc.).
						</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>

					<h2 style={{marginTop: '60px'}}>Declarações</h2>
					<p>
						A declaração é um documento de característica técnica destinada a confirmar
						uma determinada informação.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Documento de identificação válido com fotografia.</li>
						<li>Cartão de inscrição consular válido.</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>

					<h2 style={{marginTop: '60px'}}>Atestado de prova de vida</h2>
					<p>
						Documento que comprova que o cidadão está vivo e que pode continuar a
						usufruir dos seus direitos.
						<br /> Pode ser solicitado por qualquer interessado, presencialmente.
					</p>
					<h3>Requisitos</h3>
					<ul>
						<li>Uma fotografia actualizada tipo passe.</li>
						<li>
							Documento de identificação ou passaporte e cartão de inscrição consular.
						</li>
					</ul>
					<p style={{textAlign:'left'}}>
						<b>Custo:</b> Consultar a <Link to='/servicos/emolumentos'>tabela de preços.</Link>
					</p>
				</Paper>
			</Container>
		</div>
	);
}
