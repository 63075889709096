import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from '../../assets/css/header.module.scss';
import { Breadcrumbs, Container, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';
import axios from 'axios';

export default function PageLocation() {
	const [crumbs, setCrumbs] = useState([]);
	const [titulo, setTitulo] = useState('');
	const location = useLocation();
	let URL_NEWS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;
	const forceRefreshParam = `?v=${new Date().valueOf()}`;

	useEffect(() => {
		let currentPage = location.pathname.split('/');
		for (let index = currentPage.length - 1; index >= 0; index--) {
			if (currentPage[index] === '') currentPage.splice(index, 1);
			else
				currentPage[index] =
					currentPage[index].charAt(0).toUpperCase() + currentPage[index].slice(1);
		}
		setCrumbs(currentPage);

		const fetchNews = async () => {
			try {
				const id = window.location.pathname.split('/')[2];
				const response = await axios.get(URL_NEWS + '/' + id + forceRefreshParam, {
					headers: { 'x-client-id': getEncryptedClientHeaderInfo() },
				});
				setTitulo(response.data.news.title);
			} catch (err) {
				console.log(err);
			}
		};

		if (currentPage.length === 2 && currentPage[0] === 'Noticias') {
			fetchNews();
		}
	}, [location]);

	return (
		crumbs.length > 0 && (
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				className={style.locationWrapper}
				sx={{ backgroundColor: '#fff', height: 40 }}
			>
				<Container>
					<Breadcrumbs aria-label='breadcrum'>
						<Link to={'/'} className={style.pageLocationCaption}>
							<HomeIcon />
							Inicio
						</Link>

						{crumbs.map((elm, i) => {
							if (i < crumbs.length - 1) {
								return (
									<Link
										to={'/' + elm.toLowerCase()}
										key={'crumb-' + i}
										className={style.pageLocationCaption}
									>
										{elm}
									</Link>
								);
							} else if (i === 1 && crumbs[0] === 'Noticias') {
								return (
									<p key={'crumb-' + i} className={style.pageLocationCaption}>
										{titulo}
									</p>
								);
							} else
								return (
									<p key={'crumb-' + i} className={style.pageLocationCaption}>
										{elm}
									</p>
								);
						})}
					</Breadcrumbs>
				</Container>
			</Stack>
		)
	);
}
