import { Container, Stack } from '@mui/material';
import style from '../../assets/css/header.module.scss';
import emblema from '../../assets/images/logo_gb.png';
import mapa from '../../assets/images/mapa-guine-bissau.png';
import { Link } from 'react-router-dom';

export default function TopHeader() {
	return (
		<header className={style.header}>
			<Container>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					spacing={3}
					sx={{ padding: '40px 0' }}
				>
					<Link to={'/'}>
						<img className={style.brazao} src={emblema} alt='Brasão de armas' />
					</Link>
					<div className={style.middleSection}>
						<p>
							Ministério dos Negócios Estrangeiros, Cooperação Internacional e das
							Comunidades
						</p>
						<h2>Consulado Geral da República da Guiné-Bissau no Algarve</h2>
					</div>
					<img className={style.mapa} src={mapa} alt='Mapa de Guiné-Bissau' />
				</Stack>
			</Container>
		</header>
	);
}
