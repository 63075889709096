import style from '../../assets/css/newNav.module.scss';
import emblema from '../../assets/images/logo_gb.png';
import { useEffect, useState, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Container } from '@mui/material';
import signature from '../../assets/images/logo-login.png';

export default function MainMenu() {
	const [view, setView] = useState('');
	const [menuOpen, setMenuOpen] = useState(false);
	const [serviceExpand, setServiceExpand] = useState(false);
	const [consuladoExpand, setConsuladoExpand] = useState(false);
	const location = useLocation();
	const sc = classNames.bind(style);

	const navMenu = useRef(null);
	const dropdown = useRef([]);
	const pushDrodpownRef = (el) => {
		if (el !== null && dropdown.current.findIndex((x) => x.id === el.id) === -1)
			dropdown.current.push(el);
	};

	const dropdownBtn = useRef([]);
	const pushdropdownBtnRef = (el) => {
		if (el !== null && dropdownBtn.current.findIndex((x) => x.id === el.id) === -1)
			dropdownBtn.current.push(el);
	};

	let supportsPassive = false;
	const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
	const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

	try {
		window.addEventListener(
			'test',
			null,
			Object.defineProperty({}, 'passive', {
				get: () => {
					supportsPassive = true;
				},
			})
		);
	} catch (e) {}

	const wheelOpt = supportsPassive ? { passive: false } : false;
	const preventDefault = (e) => {
		e.preventDefault();
	};

	const preventDefaultForScrollKeys = (e) => {
		if (keys[e.keyCode]) {
			preventDefault(e);
			return false;
		}
	};

	const disableScroll = () => {
		window.addEventListener('DOMMouseScroll', preventDefault, false);
		window.addEventListener(wheelEvent, preventDefault, wheelOpt);
		window.addEventListener('touchmove', preventDefault, wheelOpt);
		window.addEventListener('keydown', preventDefaultForScrollKeys, false);
	};

	const enableScroll = () => {
		window.removeEventListener('DOMMouseScroll', preventDefault, false);
		window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
		window.removeEventListener('touchmove', preventDefault, wheelOpt);
		window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
	};

	useEffect(() => {
		let currentPage = location.pathname.split('/')[1];
		setView(currentPage);
	}, [location]);

	useEffect(() => {
		menuOpen ? disableScroll() : enableScroll();
		return () => {
			enableScroll();
		};
	}, [menuOpen]);

	const menuItems = [
		{ description: 'Efetuar agendamento', route: '/servicos/efetuar-agendamento' },
		{ description: 'Identificação', route: '/servicos/identificacao' },
		{ description: 'Registos', route: '/servicos/registos' },
		{ description: 'Notariado', route: '/servicos/notariado' },
		{ description: 'Migração', route: '/servicos/migracao' },
		{ description: 'Formulários', route: '/servicos/formularios' },
		{ description: 'Emolumentos', route: '/servicos/emolumentos' },
	];

	const consuladoList = [{ description: 'Mensagem do Cônsul', route: '/mensagem-consul' }];

	const onCloseDropdownMenu = (e) => {
		setServiceExpand(false);
		setConsuladoExpand(false);
		e.stopPropagation();
	};

	const onSetAriaExpandedFalse = () => {
		dropdownBtn.current.forEach((btn) => btn.setAttribute('aria-expanded', 'false'));
	};

	const onToggleMobileMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const onDropdownClick = (e) => {
		if (e.currentTarget.id === 'servico-btn') {
			setServiceExpand(!serviceExpand);
			setConsuladoExpand(false);
		}
		if (e.currentTarget.id === 'consulado-btn') {
			setConsuladoExpand(!consuladoExpand);
			setServiceExpand(false);
		}

		e.stopPropagation();
		e.currentTarget.setAttribute(
			'aria-expanded',
			e.currentTarget.getAttribute('aria-expanded') === 'false' ? 'true' : 'false'
		);
	};

	const onLinkClick = (e) => {
		onCloseDropdownMenu(e);
		onSetAriaExpandedFalse();
		setMenuOpen(false);
	};

	document.documentElement.addEventListener('click', (e) => {
		onCloseDropdownMenu(e);
		onSetAriaExpandedFalse();
	});

	document.addEventListener('keydown', (e) => {
		if (e.key === 'Escape') {
			onCloseDropdownMenu(e);
			onSetAriaExpandedFalse();
		}
	});

	return (
		<section id={style['nav-menu']} aria-label='navigation bar'>
			<Container>
				<div className={style['container']}>
					<div className={style['nav-start']}>
						<Link className={style['logo']} to='/'>
							<img src={emblema} width='35' height='35' alt='Brasão de armas' />
						</Link>
						<span className={style.mobileTitle}>CGRGBA</span>
						<nav className={sc({ menu: true, show: menuOpen })} ref={navMenu}>
							<ul className={style['menu-bar']}>
								<li>
									<Link
										className={sc({
											'nav-link': true,
											'active-page': view === '',
										})}
										to='/'
										onClick={onLinkClick}
									>
										Inicio
									</Link>
								</li>
								<li>
									<button
										className={sc({
											'nav-link': true,
											'dropdown-btn': true,
											expanded: consuladoExpand,
											'active-page': view === 'mensagem-consul',
										})}
										data-dropdown='dropdown1'
										aria-haspopup='true'
										aria-expanded='false'
										aria-label='consulado'
										id='consulado-btn'
										onClick={onDropdownClick}
										ref={pushdropdownBtnRef}
										style={{ minWidth: 165 }}
									>
										O Consulado
										<KeyboardArrowDownIcon aria-hidden='true' />
									</button>
									<div
										id='dropdown1'
										className={sc({ dropdown: true, active: consuladoExpand })}
										ref={pushDrodpownRef}
									>
										<ul role='menu'>
											{consuladoList.map((item, i) => {
												return (
													<li role='menuitem' key={'menu-consulado-' + i}>
														<Link
															className={style['dropdown-link']}
															to={item.route}
															onClick={onLinkClick}
														>
															{item.description}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								</li>
								<li>
									<button
										className={sc({
											'nav-link': true,
											'dropdown-btn': true,
											expanded: serviceExpand,
											'active-page': view === 'servicos',
										})}
										data-dropdown='dropdown2'
										aria-haspopup='true'
										aria-expanded='false'
										aria-label='serviços'
										id='servico-btn'
										onClick={onDropdownClick}
										ref={pushdropdownBtnRef}
									>
										Serviços
										<KeyboardArrowDownIcon aria-hidden='true' />
									</button>
									<div
										id='dropdown2'
										className={sc({ dropdown: true, active: serviceExpand })}
										ref={pushDrodpownRef}
									>
										<ul role='menu'>
											{menuItems.map((item, i) => {
												return (
													<li role='menuitem' key={'menu-servico-' + i}>
														<Link
															className={style['dropdown-link']}
															to={item.route}
															onClick={onLinkClick}
														>
															{item.description}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								</li>
								<li>
									<Link
										className={sc({
											'nav-link': true,
											'active-page': view === 'noticias',
										})}
										to='/noticias'
										onClick={onLinkClick}
									>
										Notícias
									</Link>
								</li>
								<li>
									<Link
										className={sc({
											'nav-link': true,
											'active-page': view === 'contactos',
										})}
										to='/contactos'
										onClick={onLinkClick}
									>
										Contactos
									</Link>
								</li>
							</ul>
							<div className={style.mobileSignature}>
								<Button
									variant='outlined'
									endIcon={<ChevronRightIcon />}
									className={style.emergencyBtn}
									sx={{ textTransform: 'none' }}
									href='/servicos/efetuar-agendamento'
								>
									Efetuar agendamento
								</Button>
								<img
									src={signature}
									className={style.mobileBrasao}
									alt='Brasão de armas'
								/>
							</div>
						</nav>
					</div>
					<div className='nav-end'>
						<button
							id={style['hamburger']}
							aria-label='hamburger'
							aria-haspopup='true'
							aria-expanded='false'
							onClick={onToggleMobileMenu}
						>
							{!menuOpen ? (
								<MenuIcon aria-hidden='true' />
							) : (
								<CloseIcon aria-hidden='true' />
							)}
						</button>
					</div>
				</div>
			</Container>
		</section>
	);
}
