export enum HttpResponseStatus {
    OK=200,
    BAD_REQUEST=400,
    UNAUTHORIZED=401,
    FORBIDDEN=403,
    NOT_FOUND=404,
    INTERNAL_SERVER_ERROR=500,
    NOT_IMPLEMENTED=501,
    SERVICE_UNAVAILABLE=503,
    SERVER_EMAIL_ERROR=535
}