import Hero from '../../components/hero/container';
import { ServicoConsular } from './ServicoConsular';
import { ContacteNos } from './ContacteNos';
import LastNews from './LastNews';

export default function HomePage() {
	return (
		<>
			<Hero />
			<LastNews />
			<ServicoConsular />
			<ContacteNos />
		</>
	);
}
