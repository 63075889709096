import {Button, Container } from '@mui/material';
import { useNavigate } from 'react-router';

export default function PageNotFound() {
  const navigate = useNavigate();


  return (
    <div className='brand-bg' style={{ padding: '24px 0' }}>
      <Container sx={{marginTop: '10px', marginBottom: '20px'}}>
      <h1>Página não encontrada</h1>
        <div>
          <Button variant="outlined" sx={{textTransform: 'none'}} size='large' onClick={()=> navigate("/")}>Ir à página inicial</Button>
        </div>
      </Container>
    </div>
  )
}
