import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';


export default function BackButton() {
    const navigate = useNavigate();
    return (
        <Box
            component="span"
            marginBottom={10}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft={0}
        >
        <Button 
            variant="outlined"
            type='button' 
            sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
            size='medium'
            onClick={()=> navigate(-1)}
            startIcon={<ArrowBackIcon />}
        >
            Voltar
        </Button>
        </Box>
    )
}
