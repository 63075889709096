import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = ({recaptchaRef}) => {

  const handleFormSubmit = async () => {
    // Trigger reCAPTCHA verification
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    // Include the reCAPTCHA value in your form submission
    console.log('', recaptchaValue);

    // Handle the rest of the form submission logic here
  };

  return (
    <div style={{zIndex: 10, position: 'relative'}}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
      />
    </div>
  );
};

export default Recaptcha;
