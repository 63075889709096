import { Container } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import exampleImg from '../../assets/images/portfolio/07-large.jpg';
import style from '../../assets/css/contatenos.module.scss';
import classNames from 'classnames/bind';

export const ContacteNos = () => {
	const sc = classNames.bind(style);
	return (
		<div id='contacteNos' className={style.contacteNos}>
			<Container>
				<h1>Contacte-nos</h1>
				<div className={style.contactosWrapper}>
					<div className={sc('linksColumn', 'imgDiv')}>
						<img src={exampleImg} alt='Computer and coffe mug' />
					</div>
					<div className={style.linksColumn}>
						<h3>Contactos</h3>
						<a href='tel:+351961941934'>
							<PhoneIphoneIcon />
							00351 961 941 934
						</a>

						<a href='tel:+351289588084'>
							<PhoneIcon />
							00351 289 588 084
						</a>

						<a href='mailto:geral@cgrgba.gw'>
							<EmailIcon />
							geral@cgrgba.gw
						</a>
					</div>
					<div className={style.linksColumn}>
						<h3>Horário de funcionamento</h3>
						<p>Segunda à Sexta-feira</p>
						<p>Abertura: 09:00 - 17:00</p>
						<p>Atendimento ao público: 09:00 - 15:30</p>
					</div>
				</div>
			</Container>
		</div>
	);
};
