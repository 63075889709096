import { Box, Card, CardMedia, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getFormattedFullDate } from '../../utils/DateUtils';
import BackButton from '../../components/ui/BackButton';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';

export default function VerNoticia() {
	const [news, setNews] = useState(null);
	const [isMobile, setIsMobile] = useState(false);
	let URL_NEWS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;
	const forceRefreshParam = `?v=${new Date().valueOf()}`;

	useEffect(() => {
		const fetchNews = async () => {
			try {
				const id = window.location.pathname.split('/')[2];
				const response = await axios.get(URL_NEWS + '/' + id + forceRefreshParam, {
					headers: { 'x-client-id': getEncryptedClientHeaderInfo() },
				});
				setNews(response.data.news);
			} catch (err) {
				console.log(err);
			}
		};
		fetchNews();
	}, [URL_NEWS]);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		news && (
			<div className='brand-bg' style={{ padding: '24px 0' }}>
				<Container>
					<Stack direction='row' alignItems='center' marginTop={2} marginBottom={2}>
						<Typography
							component='span'
							sx={{
								backgroundColor: 'hsla(0, 67%, 23%, 1)',
								color: 'hsla(0, 100%, 96%, 1)',
								fontSize: '1.3rem',
								fontWeight: 700,
								padding: '6px 16px',
								marginRight: 1.5,
							}}
						>
							{news.newsCategory.description}
						</Typography>
						<Typography
							sx={{
								fontSize: '1.3rem',
								fontWeight: 600,
								color: 'hsla(0, 67%, 23%, 1)',
								alignItems: 'center',
								display: 'inline-flex',
								marginRight: '26px',
							}}
						>
							<CalendarTodayIcon sx={{ marginRight: '4px' }} />
							{getFormattedFullDate(news.creationDate)}
						</Typography>
					</Stack>
					<h3>{news.title}</h3>
					<Paper elevation={0} sx={{ marginTop: '24px', marginBottom: '24px' }}>
						<Container sx={{ py: 8 }}>
							<Grid item xs={12} minHeight={200} marginBottom={2}>
								<Card
									raised
									sx={{
										maxWidth: isMobile ? '90%' : '60%',
										margin: '0 auto',
										padding: '0.1em',
										border: 'none',
										boxShadow: 'none',
									}}
								>
									<CardMedia
										component='img'
										width={800}
										height={500}
										image={
											news.filePath
												? `${process.env.REACT_APP_URL_API_DOMAIN}${news.filePath}`
												: ''
										}
										title='imagem da notícia'
										sx={{
											height: '100%',
											width: '100%',
											borderWidth: '24px 24px 0 24px',
											borderStyle: 'solid',
											borderColor: 'white',
											backgroundSize: 'contain',
											float: 'left',
											display: 'inline-block',
											backgroundPosition: isMobile ? 'auto' : 'center',
										}}
									/>
								</Card>
								<Box
									component='main'
									sx={{ flexGrow: 1, wordWrap: 'break-word', marginTop: '40px' }}
								>
									<p
										style={{
											textJustify: 'inter-word',
											whiteSpace: 'pre-wrap',
										}}
									>
										{news.message}
									</p>
								</Box>
							</Grid>
						</Container>
					</Paper>
					<BackButton />
				</Container>
			</div>
		)
	);
}
