import { Box, Container, Paper } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import style from '../../assets/css/servicoconsular.module.scss';
import { Link } from 'react-router-dom';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PublicIcon from '@mui/icons-material/Public';
import GavelIcon from '@mui/icons-material/Gavel';

export const ServicoConsular = () => {
	return (
		<div id='servicoConsular' className={style.servicoConsular}>
			<div className='brand-bg' style={{ padding: '24px 0 0' }}>
				<Container>
					<h1>Serviços Consulares</h1>

					<p className={style.scHeadline}>
						Consulte aqui todos os serviços que temos para si.
					</p>

					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'center',
							position: 'relative',
							top: '32px',
							marginBottom: '50px',
							'& > :not(style)': {
								m: 1,
								width: 228,
								height: 228,
								borderLeft: '2px solid #9C0010',
							},
						}}
					>
						<Paper elevation={6} square sx={{ padding: '16px' }}>
							<ContactPageIcon sx={{fontSize: '60px', color: '#9C0010'}} />
							<h3 className={style.scCaption}>Identificação</h3>
							<Link to='/servicos/identificacao' className={style.verMaisBtn}>
								Ver mais <ChevronRightIcon />
							</Link>
						</Paper>

						<Paper elevation={6} square sx={{ padding: '16px' }}>
							<TextSnippetIcon sx={{fontSize: '60px', color: '#9C0010'}} />
							<h3 className={style.scCaption}>Registo</h3>
							<Link to='/servicos/registos' className={style.verMaisBtn}>
								Ver mais <ChevronRightIcon />
							</Link>
						</Paper>

						<Paper elevation={6} square sx={{ padding: '16px' }}>
							<GavelIcon sx={{fontSize: '60px', color: '#9C0010'}} />
							<h3 className={style.scCaption}>Notariado</h3>
							<Link to='/servicos/notariado' className={style.verMaisBtn}>
								Ver mais <ChevronRightIcon />
							</Link>
						</Paper>

						<Paper elevation={6} square sx={{ padding: '16px' }}>
							<PublicIcon sx={{fontSize: '60px', color: '#9C0010'}} />
							<h3 className={style.scCaption}>Migração</h3>
							<Link to='/servicos/migracao' className={style.verMaisBtn}>
								Ver mais <ChevronRightIcon />
							</Link>
						</Paper>
					</Box>
				</Container>
			</div>
		</div>
	);
};
