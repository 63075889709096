import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Container,
	FormControl,
	InputLabel,
	Paper,
	Select,
	Stack,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronDownIcon from '@mui/icons-material/ArrowDownward';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getFormattedFullDate } from '../../utils/DateUtils';
import { Link } from 'react-router-dom';
import style from '../../assets/css/noticias.module.scss';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';


const pageSize = 10;
const newsFilters = '&orderColumn=creationDate&direction=DESC&size=9999&v=' + `${new Date().valueOf()}`;

export default function Noticias() {
	const [selectedCategory, setSelectedCategory] = useState('');
	const [news, setNews] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [newsCategoryList, setNewsCategoryList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalNews, setTotalNews] = useState(0);

	const URL_NEWS = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;
	const URL_NEWS_CATEGORIES = `${process.env.REACT_APP_URL_API_BASE}${process.env.REACT_APP_ROUTE_LOVS_NEWS_CATEGORIES}`;

	const fetchNews = async (category) => {
		try {
			setNews([]);
			setLoading(true);
			setSelectedCategory(category);

			let url = !category ? URL_NEWS +'?' : `${URL_NEWS}?category=${category}`;
			const response = await axios.get(url + newsFilters, {
				headers: { 'x-client-id': getEncryptedClientHeaderInfo() }
			});

			setNews(response.data.content);
			setTotalNews(response.data.numberOfElements);
			setLoading(false);

		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};


	const fetchMoreNews = async () => {
		try {
			setCurrentPage(previous => previous + 1);
			let url = !selectedCategory ? URL_NEWS +`?pageNumber=${currentPage + 1}` : `${URL_NEWS}?category=${selectedCategory}`;
			const response = await axios.get(url + newsFilters, {
				headers: { 'x-client-id': getEncryptedClientHeaderInfo() }
			});

			let list = [news[0], ...response.data.content];
			setNews(list);
			setLoading(false);			
		} catch (err) {
			console.log(err);
		}
	}


	const fetchNewsCategories = async () => {

		try {
			const response = await axios.get(`${URL_NEWS_CATEGORIES}?v=${new Date().valueOf()}`,
			{
				headers: { 'x-client-id': getEncryptedClientHeaderInfo() },
			});
			setNewsCategoryList(response.data.lovs);
	
		} catch (err) {
			console.log("Error: ", err);
		}
	}

	useEffect(() => {
		fetchNews();
		fetchNewsCategories();

		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const filtroChange = () => {
		const select = document.getElementById("filtro-noticia-select");
		const categoryCode = select.options[select.selectedIndex].id;
		setSelectedCategory(select.options[select.selectedIndex].text);
		fetchNews(categoryCode);
	};

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Notícias</h1>
				<Paper sx={{ padding: '16px' }}>
					<FormControl fullWidth size='small'>
						<InputLabel
							id='filtro-noticia-label'
							variant='outlined'
							sx={{ fontSize: '1.8rem' }}
						>
							Filtros
						</InputLabel>
						<Select
							id='filtro-noticia-select'
							labelId='filtro-noticia-label'
							defaultValue={'Todas'}
							label='Filtros'
							onChange={filtroChange}
							variant='outlined'
							native
							sx={{ fontSize: '1.8rem' }}
						>
							<option>Todas</option>
							{newsCategoryList.map((item, i) => (
								<option
									id={item.code}
									value={item.description}
									key={'option-' + i}
									style={{ fontSize: '1.8rem' }}
								>
									{item.description}
								</option>
							))}
						</Select>
					</FormControl>
				</Paper>



				{

				loading ? 
				<Container
					sx={{ marginBottom: '40px', marginTop: '16px' }}
				>
					<p>Por favor aguarde...</p>
				</Container>
				:


				(!news || news.length === 0 ? 
				<Container
					sx={{ marginBottom: '40px', marginTop: '16px' }}
				>
					<p>Sem resultados para apresentar</p>
				</Container>
				:
				
				news.map((item, i) => (
					<Paper
						elevation={3}
						sx={{ marginBottom: '16px', marginTop: '16px' }}
						key={`${item.subject}_${i}`}
					>
						<Card
							sx={{
								display: 'flex',
								height: isMobile ? 'auto' : '242px',
								flexDirection: isMobile ? 'column' : 'row',
							}}
						>
							<CardMedia
								component='img'
								image={
									item.filePath
										? `${process.env.REACT_APP_URL_API_DOMAIN}${item.filePath}`
										: ''
								}
								sx={{
									width: isMobile ? 'auto' : 265,
									minWidth: 265,
									margin: '16px',
								}}
							/>
							<CardContent
								component='div'
								style={{ paddingBottom: 16, width: '100%' }}
							>
								<Link to={'/noticias/' + item.id}>
									<h4 className={style.header}>{item.title}</h4>
								</Link>
								<p className={style.body}>{item.message}</p>
								<Stack
									direction='row'
									alignItems='center'
									spacing={2}
									sx={{ marginTop: '16px' }}
								>
									<p className={style.caption}>{item.newsCategory.description}</p>
									<Box component='div' style={{ marginLeft: 'auto' }}>
										<span className={style.date}>
											<CalendarTodayIcon />
											{getFormattedFullDate(item.creationDate)}
										</span>
										<Link to={'/noticias/' + item.id}>
											<Button
												variant='outlined'
												size='small'
												sx={{
													fontSize: '1.2rem',
													textTransform: 'none',
												}}
												endIcon={<ChevronRight />}
											>
												Ler artigo
											</Button>
										</Link>
									</Box>
								</Stack>
							</CardContent>
						</Card>
					</Paper>
				))
				
				)}

				{
					totalNews > news.length &&
					<div
						style={{ position: 'relative', width: '100%', height: '50px', padding: '5px' }}
					>
						<Button
							variant='text'
							endIcon={<ChevronDownIcon />}
							sx={{
								position: 'absolute',
								right: '0',
								textTransform: 'none',
								fontSize: '1.8rem',
							}}
						>
							Ver mais
						</Button>
					</div>
				}
			</Container>
		</div>
	);
}
