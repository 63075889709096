import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Container, FormControl, FormHelperText } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Constants } from '../../utils/Constants';

export default function PersonalInformation({
	fullname,
	setfullname,
	mobilenumber,
	setmobilenumber,
	email,
	setemail,
	emailconfirmation,
	setemailconfirmation,
	isinvalidfullname,
	setisinvalidfullname,
	setisinvalidemail,
	isinvalidemail,
	setisinvalidphonenumber,
	isinvalidphonenumber,
	setisinvalidconfirmationemail,
	isinvalidconfirmationemail,
}) {
	
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	
	const handleFullName = (e) => {
		e.preventDefault();
		setfullname(e.target.value);
		checkFullName();
	};

	const handleMobileNumber = (e) => {
		e.preventDefault();
		setmobilenumber(e.target.value);
		checkNumber();
	};

	const handleEmail = (e) => {
		e.preventDefault();
		setemail(e.target.value);
		checkEmail();
	};

	const handleConfirmationEmail = (e) => {
		e.preventDefault();
		setemailconfirmation(e.target.value);
		checkConfirmationEmail();
	};

	const checkNumber = () => {
		setisinvalidphonenumber(!isNotEmpty(mobilenumber) || mobilenumber.length < Constants.MINIMUN_TELEPHONE_NUMBER_LENGTH);
	};

	const checkEmail = () => {
		setisinvalidemail(isNotEmpty(email) && !emailRegex.test(email));
	};

	const checkConfirmationEmail = () => {
		setisinvalidconfirmationemail(email !== emailconfirmation && isNotEmpty(emailconfirmation));
	};

	const checkFullName = () => {
		setisinvalidfullname(
			isNotEmpty(fullname) && !(fullname.includes(' ') && fullname?.trim().length > 4 && fullname.split(' ').length >=2 )
		);
	};

	const isNotEmpty = (input) => {
		return input?.length > 0;
	};

	return (
		<React.Fragment>
			<Container>
				<h2>Por favor, insira os dados pessoais.</h2>
				<Grid container spacing={3} sx={{marginTop: '20px'}}>
					<Grid item xs={12} sm={6} marginBottom={2}>
						<FormControl error={isinvalidfullname} variant='standard' fullWidth>
							<TextField
								id='fullname'
								required
								label='Nome completo'
								fullWidth
								autoComplete='full-name'
								variant='standard'
								value={fullname}
								onChange={handleFullName}
								aria-describedby='fullname'
								onKeyUp={checkFullName}
								inputProps={{ style: { fontSize: '1.8rem' } }}
								InputLabelProps={{ style: { fontSize: '1.8rem' } }}
							/>
							{isinvalidfullname && (
								<FormHelperText
									id='fullname'
									sx={{ '&.MuiFormHelperText-root': { fontSize: '1.4rem' } }}
								>
									Formato do nome inválido
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} marginBottom={2}>
						<FormControl error={isinvalidphonenumber} variant='standard' fullWidth>
							<MuiPhoneNumber
								id='phonenumber'
								required
								label='Nº telemóvel'
								fullWidth
								autoComplete='mobileNumber'
								variant='standard'
								value={mobilenumber}
								onBlur={handleMobileNumber}
								onChange={(value)=> { setmobilenumber(value); checkNumber()}}
								aria-describedby='phonenumber'
								onKeyUp={checkNumber}
								inputProps={{ style: { fontSize: '1.8rem' } }}
								InputLabelProps={{ style: { fontSize: '1.8rem' } }}
								defaultCountry={'pt'}
								sx={{fontSize: '1.8rem'}}
							/>
							{isinvalidphonenumber && (
								<FormHelperText
									id='phonenumber'
									sx={{ '&.MuiFormHelperText-root': { fontSize: '1.4rem' } }}
								>
									Número de telemóvel inválido
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} marginBottom={2}>
						<FormControl error={isinvalidemail} variant='standard' fullWidth>
							<TextField
								required
								id='email'
								name='email'
								label='Email'
								fullWidth
								autoComplete='no'
								variant='standard'
								value={email}
								onChange={handleEmail}
								type='email'
								aria-describedby='email'
								onKeyUp={checkEmail}
								inputProps={{ style: { fontSize: '1.8rem' } }}
								InputLabelProps={{ style: { fontSize: '1.8rem' } }}
							/>
							{isinvalidemail && (
								<FormHelperText
									id='email'
									sx={{ '&.MuiFormHelperText-root': { fontSize: '1.4rem' } }}
								>
									Formato de email inválido
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} marginBottom={2}>
						<FormControl
							error={isinvalidconfirmationemail}
							variant='standard'
							fullWidth
						>
							<TextField
								required
								id='emailConfirmation'
								name='emailConfirmation'
								label='Confirmação de email'
								fullWidth
								autoComplete='off'
								variant='standard'
								value={emailconfirmation}
								onChange={handleConfirmationEmail}
								type='email'
								aria-describedby='emailConfirmation'
								onKeyUp={checkConfirmationEmail}
								inputProps={{ style: { fontSize: '1.8rem' } }}
								InputLabelProps={{ style: { fontSize: '1.8rem' } }}
							/>
							{isinvalidconfirmationemail && (
								<FormHelperText
									id='emailConfirmation'
									sx={{ '&.MuiFormHelperText-root': { fontSize: '1.4rem' } }}
								>
									O email e email de confirmação não coincidem
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}
