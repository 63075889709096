import { Alert, AlertTitle, Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

export default function Emolumentos() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container sx={{marginBottom: '80px'}}>
				<h1>Emolumentos</h1>
				<Alert
					variant='outlined'
					severity='warning'
					sx={{ marginBottom: '40px', marginTop: '4px' }}
				>
					<AlertTitle sx={{ fontSize: '1.6rem' }}>
						1. Para o pedido e emissão de qualquer documento, é obrigatório a apresentação
						da cédula de inscrição consular.
						<br />
						2. Esta obrigatoriedade não é aplicável aos cidadãos estrangeiros.
					</AlertTitle>
				</Alert>
				<div>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Passaporte
						</AccordionSummary>
						<AccordionDetails>
							<Alert
								variant='outlined'
								severity='warning'
								sx={{ marginBottom: '8px', marginTop: '4px' }}
							>
								<AlertTitle sx={{ fontSize: '1.6rem' }}>
									O depósito deve ser efetuado no balcão do Banco Montepio, das 08H30 às 12H00.
								</AlertTitle>
							</Alert>
							<Alert
								variant='outlined'
								severity='warning'
								sx={{ marginBottom: '8px', marginTop: '4px' }}
							>
								<AlertTitle sx={{ fontSize: '1.6rem' }}>
									É obrigatório a apresentação do comprovativo do pagamento/depósito, e na referência deve constar o nome do(a) requerente.
								</AlertTitle>
							</Alert>
							<ul style={{marginTop: '40px'}}>
								<li>O custo total é de 94,42€ e o depósito deverá ser efetuado em duas contas separadas:</li>
								<ul>
									<p><strong>Conta 1:</strong></p>
									<li>Nº da conta: <strong>344100015637</strong></li>
									<li>Titular da conta: <strong>INACEP</strong></li>
									<li>Valor: <strong>61,22€</strong></li>
								</ul>

								<ul style={{marginTop: '40px'}}>
									<p><strong>Conta 2:</strong></p>
									<li>Nº da conta: <strong>424106023031</strong></li>
									<li>Titular da conta: <strong>Consulado Geral da República da Guiné-Bissau</strong></li>
									<li>Valor: <strong>33,20€</strong></li>
								</ul>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='autenticacao-documentos'
							id='autenticacao-documentos'
							sx={{ fontWeight: 600 }}
						>
							Autenticação de documentos
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>15,00€
								</li>
							</ul>
							<Alert
								variant='outlined'
								severity='warning'
								sx={{
									marginBottom: '8px',
									marginTop: '10px',
								}}
							>
								<AlertTitle sx={{ fontSize: '1.6rem' }}>
									Por cada página acresce 3,00€
								</AlertTitle>
							</Alert>
						</AccordionDetails>
					</Accordion>

					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Cartão de Inscrição Consular (CIC)
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>15,00€ normal / 20,00€ urgente
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Procuração
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>10,00€
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Transcrição
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>35,00€ normal / 45,00€ urgente
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Prova de vida
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>15,00€
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Salvo Conduto
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>30,00€ normal / 40,00€ urgente
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Edital
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>10,00€
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Visto
						</AccordionSummary>
						<AccordionDetails>
							<ul style={{ marginBottom: '20px' }}>
								<li>
									<strong>Duração:</strong> 30 à 45 dias
								</li>
								<li>
									<strong>Preço:</strong> 60,00€ normal / 70,00€ urgente
								</li>
							</ul>
							<ul style={{ marginBottom: '20px' }}>
								<li>
									<strong>Duração:</strong> 90 dias
								</li>
								<li>
									<strong>Preço: </strong>
									90,00€ normal / 110,00€ urgente
								</li>
							</ul>
							<ul style={{ marginBottom: '20px' }}>
								<li>
									<strong>Duração:</strong> 180 dias
								</li>
								<li>
									<strong>Preço:</strong> 180,00€ normal / 210,00€ urgente
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Capacidade matrimonial
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>25,00€ normal / 50,00€ urgente
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Declaração para troca da carta de condução
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>25,00€
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='passaporte'
							id='passaporte'
							sx={{ fontWeight: 600 }}
						>
							Outras declarações
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
									<strong>Preço: </strong>10,00€
								</li>
							</ul>
						</AccordionDetails>
					</Accordion>
				</div>
			</Container>
		</div>
	);
}
