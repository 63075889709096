import * as React from 'react';
import {Grid, Checkbox, Box, Container } from '@mui/material';
import DataInfo from '../../components/ui/DataInfo';
import DataSection from '../../components/ui/DataSection';
import { Link } from 'react-router-dom';

function Review({
                fullname,
                mobilenumber,
                email,
                date,
                hour,
                handlechange,
                categorydescription,
                servicedescription,
                ismobile,
                checked
              }) {
  const [check, setCheck] = React.useState(checked);

  const handleChange=(e)=> {
    setCheck(e.target.checked);
    handlechange(e.target.checked);
  }


  return (
    <React.Fragment>
      <Container>
        <h2>
          Por favor, confirme os dados do agendamento antes de submeter.
        </h2>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction={ismobile ? 'column' : 'row'} sx={{marginTop: '20px'}} spacing={2}>
            <Grid item xs={4} minWidth={'md'}>
              <DataSection title={'Dados pessoais'} />
              <DataInfo label="Nome completo" content={fullname} />
              <DataInfo label="Nº telemóvel" content={mobilenumber} />
              <DataInfo label="Email" content={email} />
            </Grid>
            <Grid item xs={4}>
              <DataSection title={'Serviço'} />
              <DataInfo label="Categoria" content={categorydescription} />
              <DataInfo label="Serviço" content={servicedescription} />
            </Grid>
            <Grid item xs={4}>
              <DataSection title={'Data e hora'} />
              <DataInfo label="Data" content={date} />
              <DataInfo label="Hora" content={hour} />
            </Grid>
          </Grid>
        </Box>
        <Grid container paddingTop={10}>
          <Grid item xs={12}>
            <p>
              <Checkbox color="primary" checked={check} onChange={handleChange} />
                Li e concordo com os {' '}
              <Link to='/termos' target='_blank'>Termos de utilização</Link> e a {' '} 
              <Link to='/privacidade' target='_blank'>Politica de privacidade.</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Review