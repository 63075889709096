import { Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

export default function Privacidade() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;
			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className='brand-bg' style={{ padding: '24px 0' }}>
			<Container>
				<h1>Privacidade</h1>
				<Paper elevation={0} sx={{ padding: isMobile ? '16px 16px 60px' : '38px 38px 60px', margin: '24px 0' }}>
					<h2>Política de privacidade</h2>
					<p className='text-justify' style={{marginTop: '20px'}}>
						O Consulado Geral da República da Guiné-Bissau no Algarve (adiante abreviadamente designado por “Consulado”) 
						assume o compromisso de respeito das regras da privacidade e proteção de dados pessoais do utilizador deste site.
					</p>
					<p className='text-justify'>
						O utilizador encontra aqui as informações sobre os dados pessoais que são recolhidos neste site, 
						quando os mesmos são recolhidos e para que fins são utilizados.
					</p>
					<p className='text-justify'>
						O acesso e a utilização deste site devem ser livres por parte do utilizador, 
						presumindo o Consulado que o mesmo leu, compreendeu e aceitou os Termos e Condições da sua utilização.
					</p>
					<p className='text-justify'>
						O utilizador garante que é maior de idade e que os dados comunicados são verdadeiros, 
						exatos, completos e atuais, sendo responsável por qualquer desconformidade.
					</p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						Caso os dados comunicados pertençam a um terceiro, o utilizador garante que informou o terceiro 
						em causa sobre as condições previstas nesta política de privacidade e que 
						obteve a autorização do mesmo para facultar os seus dados ao Consulado para as finalidades indicadas.
					</p>

					<h3>1. Responsável pelo tratamento de dados</h3>

					<p className='text-justify'>
						A entidade responsável pelo tratamento dos dados pessoais é o Consulado, 
						com sede na Avenida da Liberdade n.º 131, Escritório 8, 8200-002 Albufeira.
					</p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						O utilizador pode contactar o Consulado sobre qualquer questão relacionada com a 
						presente política de privacidade, indicando como assunto “Consulado Geral da 
						República da Guiné-Bissau no Algarve - Privacidade de dados pessoais” 
						através dos seguintes pontos de contactos:
						<ul style={{marginTop: 10}}>
							<li>
								Correio postal: <strong>Avenida da Liberdade n.º 131, Escritório 8, 8200-002 Albufeira</strong>
							</li>
							<li>
								Correio eletrónico: <strong>consuladogeralgbalgarve.pt@gmail.com</strong>
							</li>
						</ul>
					</p>
					<h3>2. Âmbito de aplicação da Política de Privacidade</h3>
					<p className='text-justify'>
						Esta política de privacidade aplica-se a todos os dados pessoais recolhidos através 
						do formulário de contacto e de agendamento de serviços consulares existentes neste site.
					</p>
					<p className='text-justify'>
						Para estes efeitos, entende-se por dados pessoais todas as informações que digam respeito a 
						uma pessoa singular identificada ou identificável.
					</p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						É considerada identificável uma pessoa singular que possa ser identificada, direta ou 
						indiretamente, em especial por referência a um identificador, como por exemplo um nome, 
						um número de identificação, dados de localização, identificadores por via eletrónica.
					</p>


					<h3>3. Base jurídica para o tratamento</h3>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						Os dados pessoais do utilizador são tratados:
						<ul>
							<li>Se tiver dado o seu consentimento para o tratamento para uma ou mais finalidades 
								específicas (por exemplo, envio de informações ou responder a pedidos de contacto);
							</li>
							<li>
								Se o tratamento for necessário para o cumprimento de uma obrigação legal do Consulado;
							</li>
							<li>
								Se o tratamento for necessário ao exercício de funções de interesse público ou ao 
								exercício da autoridade pública de que está investido o Consulado. 
								Nos termos das alíneas a) a c) do artigo 24.º da Lei orgânica do Ministério dos Negócios 
								Estrangeiros, da Cooperação Internacional e das Comunidades, aprovada pelo Decreto-Lei n.º 1/2013, 
								de 1 de julho, compete ao Consulado (a) proteger no Estado recetor os interesses do Estado guineense 
								e de seus nacionais, (b) prestar toda assistência possível aos nacionais da Guiné-Bissau e (c) 
								fomentar o desenvolvimento das relações económicas, comerciais, científicas e 
								culturais entre a Guiné-Bissau e o Estado recetor e informar o Governo da Guiné-Bissau 
								da evolução dessas relações no Estado recetor.
							</li>
						</ul>
					</p>

					<h3>4. Dados pessoais recolhidos e finalidades de tratamento</h3>
					<p className='text-justify'>
						O acesso e a utilização deste site não exigem informação do utilizador, 
						salvo quando, para propósitos expressamente identificados, 
						seja necessário o preenchimento do formulário de contacto e de agendamento de serviços consulares.
					</p>
					<p className='text-justify'>
						Os dados solicitados destinam-se à prestação do serviço consular solicitado 
						pelo utilizador, não sendo recolhidos dados pessoais que não sejam necessários 
						à prestação do serviço ou sem o seu consentimento.
					</p>
					<p className='text-justify'>
						A informação recolhida, por meio do formulário de contacto e de agendamento de 
						serviços consulares, é o nome completo, o e-mail e o número de telefone, 
						destinando-se ao processamento do pedido de contacto e/ou agendamento e à 
						posterior interação do Consulado com o utilizador para efeitos de resposta 
						e prestação de serviços consulares.
					</p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						Caso o Consulado não seja a entidade competente para responder ao pedido utilizador, 
						este pode ser encaminhado para as entidades competentes sem o consentimento do utilizador, 
						desde que seja legítimo presumir que o encaminhamento do pedido salvaguarda os interesses do 
						utilizador e não afeta dados pessoais sensíveis.
					</p>

					<h3>5. Confidencialidade e privacidade</h3>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						O Consulado está empenhado em proceder ao tratamento dos dados do utilizador 
						de forma leal e transparente, garantindo confidencialidade e segurança quanto 
						às informações recolhidas e assegurando que as mesmas serão usadas apenas para 
						os fins expressamente indicados e autorizados.
					</p>

					<h3>6. Acesso, segurança e identificação do utilizador</h3>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						Toda a informação pessoal que o utilizador tiver fornecido através do formulário 
						de contacto e de agendamento dos serviços consulares encontra-se protegida 
						através de procedimentos de segurança que o protege de acessos e divulgação não autorizados.
					</p>

					<h3>7. Direito de acesso, retificação, atualização e eliminação dos dados</h3>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						O Consulado garante ao utilizador o direito de acesso aos dados que lhe digam diretamente respeito, 
						podendo proceder à sua atualização ou correção, ao seu apagamento, à limitação no 
						seu tratamento ou ao direito de se opor ao seu tratamento, sem comprometer a validade 
						do tratamento de dados efetuado até esse momento.
					</p>

					<h3>8. Arquivo dos dados pessoais</h3>
					<p className='text-justify'>
						Os dados pessoais do utilizador são conservados pelo Consulado pelo período 
						necessário ao tratamento, de acordo com a respetiva finalidade.
					</p>
					<p className='text-justify' style={{marginBottom: '60px'}}>
						Os períodos de conservação podem alterar-se quando o interesse público associado, 
						motivos históricos, científicos ou estatísticos o justifiquem, 
						comprometendo-se o Consulado a adotar as adequadas medidas de conservação e segurança.
					</p>

					<h3>9. Alteração à política de privacidade</h3>
					<p className='text-justify' style={{marginBottom: '60px', textAlign: 'left'}}>
						A presente política de privacidade, que o utilizador deverá ler atentamente, 
						poderá ser alterada, considerando-se que as alterações entram em vigor a 
						partir da data da sua publicitação neste site, fazendo-se expressa referência 
						à data de atualização.
					</p>
					<p style={{textAlign: 'left'}}>
						Data da última atualização: 25.11.2023
					</p>
				</Paper>
			</Container>
		</div>
	);
}
